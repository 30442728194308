import { computed, inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { RightSidebarConfigModel } from '../models/right-sidebar-config.model';
import { TicketsComponent } from '../../app/components/tickets/tickets.component';
import { SidebarService } from './sidebar.service';
import { TranslationService } from './translation.service';
import { SidebarHeaderService } from './sidebar-header.service';
import { TicketsSummaryModel } from '../../app/models/tickets-summary.model';
import { TicketStore } from '../../app/services/stores/ticket.store';
import { TicketModel } from '../../app/models/ticket.model';
import { OptionalType } from '../models/types/optional.type';

@Injectable({
    providedIn: 'root'
})
export class TicketService {
    selectedTicket = signal<OptionalType<TicketModel>>(undefined);
    showOnlyActionRequired = signal(false);
    readonly ticketsSummary = signal<OptionalType<TicketsSummaryModel>>(undefined);
    readonly actionNeededCount = computed(() => this.ticketsSummary()?.actionNeeded ?? 0);
    readonly actionNeededCountSubject = new BehaviorSubject<number>(0);
    readonly hasOpenTickets = computed(() => (this.ticketsSummary()?.open ?? 0) > 0);

    private readonly ticketStore = inject(TicketStore);
    private readonly sidebarService = inject(SidebarService);
    private readonly translationService = inject(TranslationService);
    private readonly headerService = inject(SidebarHeaderService);

    getTicketsSummary(): void {
        this.ticketStore.getTicketsSummary().subscribe(response => {
            this.ticketsSummary.set(response.value);
            this.actionNeededCountSubject.next(this.actionNeededCount());
        });
    }

    loadTicket(ticketId: number): Observable<boolean> {
        return this.ticketStore.getTicket(ticketId).pipe(
            map(response => {
                this.selectedTicket.set(response.value);

                return true;
            })
        );
    }

    reloadTicket(): void {
        const selectedTicket = this.selectedTicket();
        if (!!selectedTicket) {
            this.loadTicket(selectedTicket.id).subscribe();
        }
    }

    async openTicketsSidebar(onlyActionRequired = false): Promise<void> {
        this.showOnlyActionRequired.set(onlyActionRequired);

        const config = new RightSidebarConfigModel({
            component: TicketsComponent,
            width: 'full',
            maxWidth: '800px',
            shouldCloseOnBackdropClick: true,
            containerCssClasses: 'overflow-y-auto'
        });

        this.sidebarService.openRight<undefined>(config);
        this.headerService.hasCloseButton.set(true);
        const title = await this.translationService.getTextAsync('Tickets.ComponentTitle');
        this.headerService.title.set(title);
    }
}
