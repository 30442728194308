import { Component, computed, inject, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { RatingReasonsEnum } from '../../../app/models/enums/rating-reasons-enum';
import { OptionalType } from '../../../core/models/types/optional.type';

@Component({
    selector: 'arc-rate-ticket-dialog',
    templateUrl: './rate-ticket-dialog.component.html',
    styleUrl: './rate-ticket-dialog.component.scss'
})
export class RateTicketDialogComponent {
    private static readonly requireReasonThreshold = 5;

    currentRating = signal<OptionalType<number>>(undefined);
    shouldGiveRatingReason = computed(
        () => this.currentRating() !== undefined && this.currentRating()! < RateTicketDialogComponent.requireReasonThreshold
    );

    readonly ratingReason = signal<OptionalType<RatingReasonsEnum>>(undefined);

    readonly RatingReasonsEnum = RatingReasonsEnum;

    private readonly dialogRef = inject(MatDialogRef);

    onRatingUpdated(rating: number): void {
        this.currentRating.set(rating);
        if (this.currentRating() !== undefined && this.currentRating()! >= RateTicketDialogComponent.requireReasonThreshold) {
            this.ratingReason?.set(undefined);
        }
    }

    close(rate: boolean): void {
        const response = rate ? { ratingStars: this.currentRating(), ratingReason: this.ratingReason() } : undefined;

        this.dialogRef.close(response);
    }
}
