import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { PosReportParametersDialogComponent } from '../../components/pos-reports/pos-report-parameters-dialog/pos-report-parameters-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class PosReportParametersService {
    private readonly matDialog = inject(MatDialog);
    private readonly router = inject(Router);

    openReportParametersDialog(id: string, parameters?: Map<string, any>): void {
        const dialogRef = this.matDialog.open(PosReportParametersDialogComponent, {
            width: '400px',
            maxHeight: '98svh',
            maxWidth: '98vw',
            disableClose: true,
            data: { id: id, parameters: parameters || new Map() }
        });

        dialogRef.afterClosed().subscribe((result: { parameters?: any; openReport: boolean }) => {
            if (result?.openReport) {
                let params = '';
                const parametersArray: any = [];

                if (!!result.parameters) {
                    Object.entries(result.parameters).forEach(([key, value]) => {
                        parametersArray.push({ name: key, values: [value] });
                    });

                    params = window.btoa(JSON.stringify(parametersArray));
                }

                this.router.navigate(['pos-reports', 'view', `p_${id}`], { queryParams: { params: params } }).then();
            }
        });
    }
}
