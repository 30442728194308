import { Component, HostBinding, ViewChild, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { tap, switchMap, finalize } from 'rxjs';

import { DataSelectionDialogDataModel } from './models/data-selection-dialog-data.model';
import { ComplexDataTypesEnum } from '../../../core/models/complex-data-types.enum';
import { BaseSearchStore } from '../../../core/abstractions/base-search.store';
import { SearchRequestService } from '../../../core/services/search-request.service';
import { SearchRequestModel } from '../../../app/models/requests/search-request.model';
import { FilterService } from '../../../core/services/filter.service';
import { ColumnSortModel } from '../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../core/models/enums/sort-direction.enum';
import { ColumnTypeEnum } from '../../dynamic-table/models/enums/column-type.enum';
import { CustomersStore } from '../../../app/services/stores/customers.store';
import { SuppliersStore } from '../../../app/services/stores/suppliers.store';
import { articleSelectionTableConfig } from './data-selection-table-configs/article-selection-table.config';
import { customerSelectionTableConfig } from './data-selection-table-configs/customer-selection-table.config';
import { supplierSelectionTableConfig } from './data-selection-table-configs/supplier-selection-table.config';
import { BaseColumnModel } from '../../dynamic-table/models/column-types/base-column.model';
import { DataSelectionDialogCustomDataModel } from './models/data-selection-dialog-custom-data.model';
import { Identifyable } from '../../../core/abstractions/identifyable';
import { SubscriptionTypeArticlesStore } from '../../../app/services/stores/subscription-type-article.store';
import { ArticlesStore } from '../../../app/services/stores/articles.store';
import { BaseSelectableTable } from '../../../core/abstractions/base-selectable-table';
import { TableSelectionModel } from '../../../core/models/table-selection.model';

@Component({
    selector: 'arc-data-selection-dialog',
    templateUrl: './data-selection-dialog.component.html',
    styleUrls: ['./data-selection-dialog.component.scss'],
    providers: [SearchRequestService, FilterService] // data selection dialog needs its own instances of these services
})
export class DataSelectionDialogComponent<T extends Identifyable<TId>, TId> extends BaseSelectableTable<T> {
    @HostBinding('class') classes = 'grow flex flex-col overflow-y-auto';

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ComplexDataTypesEnum = ComplexDataTypesEnum;
    ColumnTypeEnum = ColumnTypeEnum;

    selectionModel: TableSelectionModel<T>;
    totalRecords = 0;
    displayedColumns: string[] = [];
    pageSize = 15;
    pageSizeOptions = [10, 15, 20, 25];
    isLoading = signal(true);
    dialogTitleKey: string;
    store: BaseSearchStore<T, TId>;
    columnConfig: BaseColumnModel[];

    protected readonly _dialogData: DataSelectionDialogDataModel | DataSelectionDialogCustomDataModel<T, TId> = inject(MAT_DIALOG_DATA);

    private readonly matDialogRef = inject(MatDialogRef);
    private readonly searchRequestService = inject(SearchRequestService);
    private readonly articlesStore = inject(ArticlesStore);
    private readonly customersStore = inject(CustomersStore);
    private readonly suppliersStore = inject(SuppliersStore);
    private readonly subscriptionTypeArticlesStore = inject(SubscriptionTypeArticlesStore);

    constructor() {
        super();

        this.selectionModel = new TableSelectionModel<T>(item => item.id);

        if (this._dialogData instanceof DataSelectionDialogCustomDataModel) {
            this.store = this._dialogData.store;
            this.columnConfig = this._dialogData.columnConfig;
            this.dialogTitleKey = this._dialogData.dialogTitleKey;
        } else {
            // set store from type
            switch (this._dialogData.type) {
                case ComplexDataTypesEnum.Article:
                    // this is the default article search excluding main articles of variants, as the main article cannot be selected.
                    this.store = this.articlesStore as any;
                    break;
                case ComplexDataTypesEnum.Customer:
                    this.store = this.customersStore as any;
                    break;
                case ComplexDataTypesEnum.Supplier:
                    this.store = this.suppliersStore as any;
                    break;
                case ComplexDataTypesEnum.SubscriptionTypeArticle:
                    this.store = this.subscriptionTypeArticlesStore as any;
                    break;
            }

            // set column config
            if (!!this._dialogData.customColumnConfig) {
                this.columnConfig = this._dialogData.customColumnConfig;
            } else {
                switch (this._dialogData.type) {
                    case ComplexDataTypesEnum.Article:
                        this.columnConfig = articleSelectionTableConfig;
                        break;
                    case ComplexDataTypesEnum.Customer:
                        this.columnConfig = customerSelectionTableConfig;
                        break;
                    case ComplexDataTypesEnum.Supplier:
                        this.columnConfig = supplierSelectionTableConfig;
                        break;
                    case ComplexDataTypesEnum.SubscriptionTypeArticle:
                        this.columnConfig = articleSelectionTableConfig;
                        break;
                }
            }

            // set dialog title
            this.dialogTitleKey = {
                [ComplexDataTypesEnum.Article]: 'Articles.TitleSelectDialog',
                [ComplexDataTypesEnum.Customer]: 'Customers.TitleSelectDialog',
                [ComplexDataTypesEnum.Supplier]: 'Suppliers.TitleSelectDialog',
                [ComplexDataTypesEnum.SubscriptionTypeArticle]: 'Articles.TitleSelectDialog'
            }[this._dialogData.type];
        }

        this.displayedColumns = this.columnConfig?.map(c => c.propertyName!) ?? [];

        if (this._dialogData.isMultiSelect) {
            this.displayedColumns = ['selection', ...this.displayedColumns];
        }

        this.searchRequestService.init(new SearchRequestModel({ pageSize: this.pageSize }));

        const loadingStartSub = this.searchRequestService.loadingStart$.subscribe(() => this.isLoading.set(true));
        const searchRequestChangedSub = this.searchRequestService.searchRequestChanged$
            .pipe(
                tap(() => this.isLoading.set(true)),
                switchMap(searchRequest => this.store.search(searchRequest))
            )
            .subscribe(result => {
                if (this.totalRecords !== result.value?.totalRecords) {
                    this.paginator.firstPage();
                }

                this.isLoading.set(false);
                this.data.set(result.value?.records ?? []);

                this.totalRecords = result.value?.totalRecords ?? 0;
            });

        this.addSubscriptions(loadingStartSub, searchRequestChangedSub);

        this.searchRequestService.forceReload();
    }

    select(entry: T): void {
        if (this._dialogData.isMultiSelect) {
            return;
        }

        if (!this._dialogData.isMultiSelect) {
            this.matDialogRef.close([entry]);
        }

        this.itemSelectionChanged(entry);
    }

    handleSort(sort: Sort): void {
        this.searchRequestService.setSortings([
            new ColumnSortModel({
                column: sort.active,
                direction: sort.direction === 'desc' ? SortDirectionEnum.Desc : SortDirectionEnum.Asc
            })
        ]);
    }

    handlePageEvent(event: PageEvent): void {
        this.searchRequestService.setPaginatorOptions(event.pageIndex, event.pageSize);
    }

    loadAndSelectAll(): void {
        this.isLoading.set(true);
        this.store.search(new SearchRequestModel({ pageSize: 999999 }))
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe(result => this.matDialogRef.close(result.value?.records ?? []));
    }
}
