<div class="w-5/12 grow flex gap-2 min-w-fit">
    <mat-form-field *ngIf="index !== 0" class="shrink max-w-[92px]" subscriptSizing="dynamic">
        <mat-select [(ngModel)]="filter.operand" (selectionChange)="handleChange()">
            <mat-option [value]="OperandTypeEnum.And">
                {{ 'Components.SearchFilter.FilterOperands.And' | transloco }}
            </mat-option>
            <mat-option [value]="OperandTypeEnum.Or">
                {{ 'Components.SearchFilter.FilterOperands.Or' | transloco }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="grow" subscriptSizing="dynamic">
        <mat-select [(ngModel)]="filter.column" (selectionChange)="handleColumnChanged()">
            <mat-option *ngFor="let prop of filterProperties" [value]="prop.columnName">{{ prop.label }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="w-6/12 grow-[9999] flex gap-2">
    <mat-form-field class="w-1/3 shrink-0 grow" subscriptSizing="dynamic">
        <mat-select [(ngModel)]="filter.comparisonOperator" (selectionChange)="handleChange()"
            [disabled]="!filter.column || !!allowedValues || currentFilterComparisonOperators.length === 1">
            <mat-option *ngFor="let operator of currentFilterComparisonOperators" [value]="operator.operator">
                {{ operator.labelKey | transloco }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="!!currentComparisonOperator?.expectedParameterAmount">
        <ng-container [ngSwitch]="currentFilterProperty?.type" *ngIf="!allowedValues; else allowedValuesSelect">
            <ng-container *ngSwitchCase="FilterItemTypeEnum.Date">
                <mat-form-field *ngIf="filter.comparisonOperator !== ComparisonOperatorsEnum.InRange; else rangePicker"
                    subscriptSizing="dynamic" class="grow-[9999]">
                    <input matInput [(ngModel)]="filter.values[0]" (dateInput)="handleChange()" [matDatepicker]="picker"
                        [placeholder]="dateFormatPlaceholder">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <ng-template #rangePicker>
                    <mat-form-field subscriptSizing="dynamic" class="grow-[9999]">
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate [(ngModel)]="filter.values[0]" (dateInput)="handleChange()"
                                [placeholder]="dateFormatPlaceholder"/>
                            <input matEndDate [(ngModel)]="filter.values[1]" (dateInput)="handleChange()"
                                [placeholder]="dateFormatPlaceholder"/>
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="FilterItemTypeEnum.Time">
                <mat-form-field subscriptSizing="dynamic" class="grow-[9999]">
                    <mat-select [(ngModel)]="filter.values[0]" (selectionChange)="handleChange()"
                        [disabled]="!filter.column">
                        <mat-option *ngFor="let time of allowedTimeValues" [value]="time.value">
                            {{ time.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="filter.comparisonOperator === ComparisonOperatorsEnum.InRange"
                    subscriptSizing="dynamic" class="grow-[9999]">
                    <mat-select [(ngModel)]="filter.values[1]" (selectionChange)="handleChange()"
                        [disabled]="!filter.column">
                        <mat-option *ngFor="let time of allowedTimeValues" [value]="time.value">
                            {{ time.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="FilterItemTypeEnum.Boolean"></ng-container>
            <ng-container *ngSwitchCase="FilterItemTypeEnum.Number">
                <mat-form-field subscriptSizing="dynamic" class="grow-[9999]">
                    <input matInput [(ngModel)]="filter.values[0]" type="number" (input)="handleChange()"/>
                </mat-form-field>
                <mat-form-field *ngIf="filter.comparisonOperator === ComparisonOperatorsEnum.InRange"
                    subscriptSizing="dynamic" class="grow-[9999]">
                    <input matInput [(ngModel)]="filter.values[1]" type="number" (input)="handleChange()"/>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="FilterItemTypeEnum.Group">
                <arc-tree-autocomplete [(ngModel)]="filter.values[0]" (ngModelChange)="handleChange()" class="grow-[9999]"
                    [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfigForSelection">
                </arc-tree-autocomplete>
            </ng-container>
            <ng-container *ngSwitchCase="FilterItemTypeEnum.Supplier">
                <arc-quick-search [config]="ComplexDataTypesEnum.Supplier | arcQuickSearchConfig" [(ngModel)]="filter.values[0]" (ngModelChange)="handleChange()" class="grow-[9999]">
                </arc-quick-search>
            </ng-container>
            <mat-form-field *ngSwitchDefault subscriptSizing="dynamic" class="grow-[9999]">
                <input matInput [(ngModel)]="filter.values[0]" (input)="handleChange()"/>
            </mat-form-field>
        </ng-container>
    </ng-container>

    <ng-template #allowedValuesSelect>
        <mat-form-field class="w-1/3 shrink-0 grow" subscriptSizing="dynamic">
            <mat-select [(ngModel)]="filter.values[0]" (selectionChange)="handleChange()" [disabled]="!filter.column">
                <mat-option *ngFor="let keyValue of allowedValues" [value]="keyValue.key">
                    {{ keyValue.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>

    <div class="flex items-center">
        <button mat-icon-button (click)="handleRemove()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
