import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[autofocus]' })
export class AutoFocusDirective implements AfterViewInit {
    private readonly host = inject(ElementRef);

    ngAfterViewInit(): void {
        this.host.nativeElement.focus();
    }
}
