import { Component, input, model, output } from '@angular/core';

@Component({
    selector: 'arc-rating',
    templateUrl: './rating.component.html',
    styleUrl: './rating.component.scss'
})
export class RatingComponent {
    readonly rating = model(0);
    readonly starCount = input<number>(5);
    readonly size = input<number>(50);
    readonly ratingUpdated = output<number>();

    stars: boolean[] = Array(this.starCount()).fill(false);

    constructor() {
        this.calculateStars(this.rating());
    }

    onClick(rating: number): void {
        this.rating.update(() => rating);
        this.calculateStars(rating);

        this.ratingUpdated.emit(this.rating());
    }

    onMouseOver(index: number): void {
        this.calculateStars(index);
    }

    onMouseLeave(): void {
        this.calculateStars(this.rating());
    }

    private calculateStars(rating: number): void {
        this.stars = Array(this.starCount())
            .fill(false)
            .map((v, i) => i < rating);
    }
}
