import { RouteModel } from '../../core/models/route.model';
import { UsersComponent } from '../views/settings/users/users.component';
import { PosComponent } from '../views/settings/pos/pos.component';
import { BranchesComponent } from '../views/settings/branches/branches.component';
import { RegionsComponent } from '../views/settings/regions/regions.component';
import { PosLayoutsComponent } from '../views/settings/poslayouts/poslayouts.component';
import { PosCustomerScreenLayoutsComponent } from '../views/settings/poscustomer-screen-layouts/poscustomer-screen-layouts.component';
import { RolesComponent } from '../views/settings/roles/roles.component';
import { TransactionTypesComponent } from '../views/settings/transaction-types/transaction-types.component';
import { ResourcesComponent } from '../views/settings/resources/resources.component';
import { SystemLogComponent } from '../views/settings/system-log/system-log.component';
import { PosReportsComponent } from '../views/settings/pos-reports/pos-reports.component';
import { MessagesComponent } from '../views/settings/messages/messages.component';
import { ArticleGroupsComponent } from '../views/settings/article-groups/article-groups.component';
import { AccountingExportRecordsComponent } from '../views/settings/accounting-export-records/accounting-export-records.component';
import { ImportDefinitionsComponent } from '../views/transactions/import-definitions/import-definitions.component';
import { UnitsComponent } from '../views/settings/units/units.component';
import { SettingsComponent } from '../views/settings/settings/settings.component';
import { PermissionsEnum } from '../models/enums/permissions.enum';
import { MyProfileComponent } from '../views/settings/my-profile/my-profile.component';
import { RecordChangesComponent } from '../views/settings/record-changes/record-changes.component';
import { RecycleBinComponent } from '../views/settings/recycle-bin/recycle-bin.component';
import { ExtensionsComponent } from '../views/settings/extensions/extensions.component';
import { UnsavedChangesGuard } from '../../core/services/guards/unsaved-changes.guard';
import { PaymentTypesComponent } from '../views/settings/payment-types/payment-types.component';
import { CustomerGroupsComponent } from '../views/settings/customer-groups/customer-groups.component';
import { TagsComponent } from '../views/articles/tags/tags.component';

export const settingsRoutes: RouteModel[] = [
    {
        path: 'settings',
        children: [
            {
                path: 'base-settings',
                component: SettingsComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 0,
                    title: 'Settings.BaseSettings',
                    requiredPermission: PermissionsEnum.Configuration
                }
            },
            {
                path: 'users',
                component: UsersComponent,
                data: { subMenu: 'settings', subMenuIndex: 1, title: 'Settings.Users', requiredPermission: PermissionsEnum.Users }
            },
            {
                path: 'pos',
                component: PosComponent,
                data: { subMenu: 'settings', subMenuIndex: 2, title: 'Settings.Pos', requiredPermission: PermissionsEnum.PointOfSales }
            },
            {
                path: 'branches',
                component: BranchesComponent,
                data: { subMenu: 'settings', subMenuIndex: 3, title: 'Settings.Branches', requiredPermission: PermissionsEnum.Branches }
            },
            {
                path: 'regions',
                component: RegionsComponent,
                data: { subMenu: 'settings', subMenuIndex: 4, title: 'Settings.Regions', requiredPermission: PermissionsEnum.Regions }
            },
            {
                path: 'pos-layouts',
                component: PosLayoutsComponent,
                data: { subMenu: 'settings', subMenuIndex: 5, title: 'Settings.PosLayouts', requiredPermission: PermissionsEnum.PosLayouts }
            },
            {
                path: 'pos-reports',
                component: PosReportsComponent,
                data: { subMenu: 'settings', subMenuIndex: 6, title: 'Settings.PosReports', requiredPermission: PermissionsEnum.PosReports }
            },
            {
                path: 'pos-customer-screen-layouts',
                component: PosCustomerScreenLayoutsComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 7,
                    title: 'Settings.PosCustomerScreenLayouts',
                    requiredPermission: PermissionsEnum.PosCustomerScreenLayouts,
                    isDisabled: true
                }
            },
            {
                path: 'extensions',
                component: ExtensionsComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 8,
                    title: 'Settings.Extensions',
                    requiredPermission: PermissionsEnum.Extensions
                }
            },
            {
                path: 'scripts',
                component: ImportDefinitionsComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 9,
                    title: 'Sales.Scripts',
                    requiredPermission: PermissionsEnum.ImportDefinitions
                }
            },
            {
                path: 'roles',
                component: RolesComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 10,
                    title: 'Settings.Roles',
                    requiredPermission: PermissionsEnum.Roles
                }
            },
            {
                path: 'accounting-records',
                component: AccountingExportRecordsComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 11,
                    title: 'Sales.AccountingRecords',
                    requiredPermission: PermissionsEnum.ExtAccountingExport
                }
            },
            {
                path: 'resources',
                component: ResourcesComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 12,
                    title: 'Settings.Resources',
                    requiredPermission: PermissionsEnum.Resources,
                    hideIfPermissionMissing: true,
                    isDisabled: true
                }
            },
            {
                path: 'article-groups',
                component: ArticleGroupsComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 13,
                    title: 'Settings.ArticleGroups',
                    requiredPermission: PermissionsEnum.ArticleGroups
                }
            },
            {
                path: 'customer-groups',
                component: CustomerGroupsComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 14,
                    title: 'Settings.CustomerGroups',
                    requiredPermission: PermissionsEnum.CustomerGroups
                }
            },
            {
                path: 'units',
                component: UnitsComponent,
                data: { subMenu: 'settings', subMenuIndex: 15, title: 'Settings.Units', requiredPermission: PermissionsEnum.Units }
            },
            {
                path: 'tags',
                component: TagsComponent,
                data: { subMenu: 'settings', subMenuIndex: 16, title: 'Settings.Tags', requiredPermission: PermissionsEnum.Tags }
            },
            {
                path: 'transaction-types',
                component: TransactionTypesComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 17,
                    title: 'Settings.TransactionTypes',
                    requiredPermission: PermissionsEnum.TransactionTypes
                }
            },
            {
                path: 'payment-types',
                component: PaymentTypesComponent,
                data: {
                    subMenu: 'settings', subMenuIndex: 18, title: 'Settings.PaymentTypes', requiredPermission: PermissionsEnum.PaymentTypes
                }
            },
            // TODO: implement permissions for RecordChanges / History
            {
                path: 'history',
                component: RecordChangesComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 19,
                    title: 'Settings.History'
                }
            },
            {
                path: 'recycle-bin',
                component: RecycleBinComponent,
                data: {
                    subMenu: 'settings',
                    subMenuIndex: 20,
                    title: 'Settings.RecycleBin'
                }
            },
            {
                path: 'system-log',
                component: SystemLogComponent,
                data: { subMenu: 'settings', subMenuIndex: 21, title: 'Settings.SystemLog', requiredPermission: PermissionsEnum.Log }
            },

            /* Not part of the menu */
            { path: 'messages', component: MessagesComponent, data: { requiredPermission: PermissionsEnum.Messages } },
            {
                path: 'my-profile',
                component: MyProfileComponent,
                canDeactivate: [UnsavedChangesGuard]
            }
        ]
    }
];
