import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { TransactionTypeEditModel } from '../../../../../models/requests/transaction-type-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { TransactionPurposeModel } from '../../../../../models/transaction-purpose.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { ButtonToggleColumnModel } from '../../../../../../components/dynamic-table/models/column-types/button-toggle-column.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import {
    TranslationStringColumnModel
} from '../../../../../../components/dynamic-table/models/column-types/translation-string-column.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { TransactionTypeModel } from '../../../../../models/transaction-type.model';

@Component({
    selector: 'arc-transaction-type-payment-purposes',
    templateUrl: './transaction-type-payment-purposes.component.html',
    styleUrl: './transaction-type-payment-purposes.component.scss'
})
export class TransactionTypePaymentPurposesComponent extends BaseEditSidebarItemComponent<TransactionTypeModel, TransactionTypeEditModel> {
    override formGroup = new FormGroup({
        transactionPurposes: new ArcFormControl<TransactionPurposeModel[]>([])
    });
    transactionPurposesTableConfig: EditableTableConfigModel<TransactionPurposeModel>;

    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        this.transactionPurposesTableConfig = new EditableTableConfigModel<TransactionPurposeModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    title_de: new ArcFormControl('', Validators.required),
                    title_en: new ArcFormControl<OptionalType<string>>(undefined),
                    title_fr: new ArcFormControl<OptionalType<string>>(undefined),
                    title_it: new ArcFormControl<OptionalType<string>>(undefined),
                    debit: new ArcFormControl(false),
                    transactionTypeId: new ArcFormControl(this.item().id)
                }),
            rowHeightPx: 63,
            rowGapPx: 12,
            columns: [
                new TranslationStringColumnModel({
                    controlNameDe: 'title_de',
                    controlNameEn: 'title_en',
                    controlNameFr: 'title_fr',
                    controlNameIt: 'title_it',
                    isEditable: true,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentPurposes.Title',
                    widthPixels: 300
                }),
                new ButtonToggleColumnModel({
                    propertyName: 'debit',
                    isEditable: true,
                    widthPixels: 350,
                    options: [
                        { key: true, value: this.translationService.getText('TransactionTypes.Edit.PaymentPurposes.Debit') },
                        { key: false, value: this.translationService.getText('TransactionTypes.Edit.PaymentPurposes.Credit') }
                    ]
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.transactionPurposesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<TransactionTypeEditModel> {
        return { transactionPurposes: this.formGroup.value.transactionPurposes };
    }
}
