<div #scrollContainer class="flex flex-col min-h-96 gap-4 grow overflow-y-auto scroll-smooth pl-8 pr-4 pb-4">
    @if(messagesOrEvents().length > 0) {
        <div class="flex flex-col gap-4 first:pt-6">
            @for(messageOrEvent of messagesOrEvents(); track messageOrEvent.id) {
                @if(messageOrEvent.type === TicketMessageTypeEnum.Message) {
                    <arc-ticket-message-item [ticketId]="ticket().id" [message]="asMessage(messageOrEvent.item)"></arc-ticket-message-item>
                } @else {
                    <arc-event-message-item [event]="asEvent(messageOrEvent.item)"></arc-event-message-item>
                }
            }
        </div>
    }
    <arc-markdown-editor #markdown [formControl]="formGroup.controls.text" styleClass="mde-small" imageUploadLocation="portaldb" />
    <arc-file-upload class="block w-full !h-[60px] px-[2px] mb-2" [shouldForceMinHeight]="false" [formControl]="fileControl"
        [allowUrlUpload]="false" [maxFileSizeInKb]="2048">
    </arc-file-upload>
    @for(file of formGroup.value.attachments; track file.fileName) {
        <div class="flex items-center p-4 bg-app rounded-lg">
            <mat-icon>insert_drive_file</mat-icon>
            <span class="ml-2">{{ file.fileName }}</span>
            <button mat-icon-button class="ml-auto" (click)="removeFile(file.blobDataId)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    }
</div>
<div class="flex flex-col gap-4 z-[200] bg-element">
    <div class="flex items-center justify-between p-8 smd:py-4 shadow-[rgba(0,0,15,0.5)_1px_-6px_9px_-11px] z-[200]">
        <button mat-stroked-button (click)="cancel()">
            {{ 'General.Actions.Cancel' | transloco }}
        </button>
        <div class="flex gap-2 items-center">
            @if(isResolveSuggested()){
                <arc-button type="stroked" (clicked)="addMessage()" [isLoading]="isAdding()">
                    {{ 'Tickets.View.AddMessage' | transloco }}
                </arc-button>
                <arc-button type="flat" color="accent" (clicked)="resolveTicket()" [isLoading]="isResolving()">
                    {{ 'Tickets.View.Resolve' | transloco }}
                </arc-button>
            }
            @else {
                <arc-button type="stroked" (clicked)="resolveTicketConfirm()" [isLoading]="isResolving()">
                    {{ 'Tickets.View.Resolve' | transloco }}
                </arc-button>
                <arc-button type="flat" color="accent" [isLoading]="isAdding()" (clicked)="addMessage()">
                    {{ 'Tickets.View.AddMessage' | transloco }}
                </arc-button>
            }
        </div>
    </div>
</div>
