export enum FilterItemTypeEnum {
    String = 1,
    Number = 2,
    Date = 3,
    Boolean = 4,
    Guid = 5,
    Time = 6,
    Group = 7,
    Supplier = 8,
}
