import { ComplexDataTypesEnum } from '../../../../core/models/complex-data-types.enum';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';

export class DataSelectionDialogDataModel {
    type!: ComplexDataTypesEnum;
    customColumnConfig?: BaseColumnModel[];
    isMultiSelect?: boolean;
    shouldDisplaySelectAllButton? = false;

    constructor(init: DataSelectionDialogDataModel) {
        this.type = init.type;
        this.customColumnConfig = init.customColumnConfig;
        this.isMultiSelect = init.isMultiSelect ?? false;
    }
}
