import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { CustomerProfileModel } from '../../../../../models/customer-profile.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomerProfileDiscountModel } from '../../../../../models/customer-profile-discount.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { QuickSearchColumnModel } from '../../../../../../components/dynamic-table/models/column-types/quick-search-column.model';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { TreeAutocompleteColumnModel } from '../../../../../../components/dynamic-table/models/column-types/tree-autocomplete-column.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-customer-profiles-discounts',
    templateUrl: './customer-profiles-discounts.component.html',
    styleUrl: './customer-profiles-discounts.component.scss'
})
export class CustomerProfilesDiscountsComponent extends BaseEditSidebarItemComponent<CustomerProfileModel> {
    override formGroup = new FormGroup({
        cumulateDiscounts: new ArcFormControl(false),
        isDiscountLocked: new ArcFormControl(false),
        customerProfileDiscounts: new ArcFormControl<CustomerProfileDiscountModel[]>([])
    });
    discountsTableConfig: EditableTableConfigModel<CustomerProfileDiscountModel>;

    constructor() {
        super();
        this.discountsTableConfig = new EditableTableConfigModel<CustomerProfileDiscountModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    articleGroupId: new ArcFormControl<OptionalType<number>>(undefined),
                    supplierPersonId: new ArcFormControl<OptionalType<number>>(undefined),
                    discountPercent: new ArcFormControl<OptionalType<number>>(undefined, [
                        Validators.required, CustomValidators.number({ min: 0.0, max: 100.0 })
                    ])
                }),
            rowHeightPx: 63,
            columns: [
                new TreeAutocompleteColumnModel({
                    propertyName: 'articleGroupId',
                    isEditable: true,
                    columnTitleKey: 'CustomerProfiles.Edit.Discounts.ArticleGroup',
                    allowSelectionAtLevels: [0, 1, 2],
                    widthPixels: 260
                }),
                new QuickSearchColumnModel({
                    propertyName: 'supplierPersonId',
                    isEditable: true,
                    type: ComplexDataTypesEnum.Supplier,
                    columnTitleKey: 'CustomerProfiles.Edit.Discounts.Supplier',
                    requiredPermission: PermissionsEnum.ArticleSuppliers,
                    widthPixels: 230
                }),
                new NumberColumnModel({
                    propertyName: 'discountPercent',
                    isEditable: true,
                    unit: UnitsEnum.Percent,
                    columnTitleKey: 'CustomerProfiles.Edit.Discounts.DiscountPercent'
                })
            ]
        });
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<CustomerProfileModel> {
        const value = this.formGroup.getRawValue();
        return {
            cumulateDiscounts: value.cumulateDiscounts,
            isDiscountLocked: value.isDiscountLocked,
            customerProfileDiscounts: value.customerProfileDiscounts
        };
    }

    override onBrokenRulesLoad(): string[] {
        return this.discountsTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }
}
