import { Component, inject } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { CustomerProfileModel } from '../../../../../models/customer-profile.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomerBonusProgramModel } from '../../../../../models/customer-bonus-program.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { GeneralDataColumnModel } from '../../../../../../components/dynamic-table/models/column-types/general-data-column.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { UserService } from '../../../../../../core/services/user.service';
import { AuthPermissionModel } from '../../../../../models/auth-permission.model';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { ButtonToggleModel } from '../../../../../../core/models/button-toggle.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-customer-bonus-programs',
    templateUrl: './customer-bonus-programs.component.html',
    styleUrl: './customer-bonus-programs.component.scss'
})
export class CustomerBonusProgramsComponent extends BaseEditSidebarItemComponent<CustomerProfileModel> {
    override formGroup = new FormGroup({
        isDiscount: new ArcFormControl(true),
        cashbackInPercent: new ArcFormControl(false),
        noRemainingBonusPoints: new ArcFormControl(false),
        bonusFactor: new ArcFormControl<OptionalType<number>>(undefined),
        customerDiscounts: new ArcFormControl<CustomerBonusProgramModel[]>([]),
        customerCashbacks: new ArcFormControl<CustomerBonusProgramModel[]>([]),
        customerCashbacksInPercent: new ArcFormControl<CustomerBonusProgramModel[]>([])
    }, { validators: this.requireBonusFactorIfAnyBonusPointsValidator() });
    discountTableConfig: EditableTableConfigModel<CustomerBonusProgramModel>;
    cashbackTableConfig: EditableTableConfigModel<CustomerBonusProgramModel>;
    cashbackInPercentTableConfig: EditableTableConfigModel<CustomerBonusProgramModel>;
    voucherPermission: true | AuthPermissionModel = true;

    readonly voucherPermissionItems: ButtonToggleModel[] = [];
    readonly translationService = inject(TranslationService);

    private readonly userService = inject(UserService);
    private readonly permissionsService = inject(PermissionService);

    constructor() {
        super();

        this.voucherPermission = this.permissionsService.hasPermission(PermissionsEnum.Vouchers);
        this.discountTableConfig = new EditableTableConfigModel<CustomerBonusProgramModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    requiredBonusPoints: new ArcFormControl<OptionalType<number>>(
                        undefined,
                        [CustomValidators.number({ min: 0.01 }), Validators.required]
                    ),
                    paymentVoucherTypeId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required)
                }, { validators: this.uniqueBonusPointsValidator() }),
            rowHeightPx: 63,
            columns: [
                new NumberColumnModel({
                    propertyName: 'requiredBonusPoints',
                    isEditable: true,
                    unit: UnitsEnum.Points,
                    columnTitleKey: 'CustomerProfiles.Edit.BonusPrograms.RequiredBonusPoints'
                }),
                new GeneralDataColumnModel({
                    propertyName: 'paymentVoucherTypeId',
                    isEditable: true,
                    widthPixels: 350,
                    columnTitleKey: 'CustomerProfiles.Edit.BonusPrograms.Voucher',
                    generalDataType: GeneralDataTypeEnum.VoucherDiscounts
                })
            ]
        });

        this.cashbackTableConfig = new EditableTableConfigModel<CustomerBonusProgramModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    requiredBonusPoints: new ArcFormControl(0,
                        [CustomValidators.number({ min: 0.01 }), Validators.required]
                    ),
                    bonusValue: new ArcFormControl('', [CustomValidators.number({ min: 0.01 }), Validators.required]),
                    paymentAccountTypeId: new ArcFormControl<number>(undefined, [Validators.required])
                }, { validators: this.uniqueBonusPointsValidator() }),
            rowHeightPx: 63,
            columns: [
                new NumberColumnModel({
                    propertyName: 'requiredBonusPoints',
                    isEditable: true,
                    unit: UnitsEnum.Points,
                    columnTitleKey: 'CustomerProfiles.Edit.BonusPrograms.RequiredBonusPoints',
                    widthPixels: 120
                }),
                new NumberColumnModel({
                    propertyName: 'bonusValue',
                    isEditable: true,
                    decimalPlaces: 2,
                    unit: this.userService.getUserInfo()?.currencyIsoCode,
                    columnTitleKey: 'CustomerProfiles.Edit.BonusPrograms.BonusValue',
                    widthPixels: 135
                }),
                new GeneralDataColumnModel({
                    propertyName: 'paymentAccountTypeId',
                    isEditable: true,
                    columnTitleKey: 'CustomerProfiles.Edit.BonusPrograms.PayoutOn',
                    generalDataType: GeneralDataTypeEnum.AccountTypesForCustomerProfile,
                    widthPixels: 350
                })
            ]
        });

        this.cashbackInPercentTableConfig = new EditableTableConfigModel<CustomerBonusProgramModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    bonusValue: new ArcFormControl('', [Validators.required, CustomValidators.number({ min: 0, max: 99.99 })]),
                    paymentAccountTypeId: new ArcFormControl<number>(undefined, [Validators.required])
                }),
            rowHeightPx: 63,
            columns: [
                new NumberColumnModel({
                    propertyName: 'bonusValue',
                    isEditable: true,
                    unit: UnitsEnum.Percent,
                    decimalPlaces: 2,
                    columnTitleKey: 'CustomerProfiles.Edit.BonusPrograms.PercentOfBalance'
                }),
                new GeneralDataColumnModel({
                    propertyName: 'paymentAccountTypeId',
                    isEditable: true,
                    columnTitleKey: 'CustomerProfiles.Edit.BonusPrograms.PayoutOn',
                    generalDataType: GeneralDataTypeEnum.AccountTypesForCustomerProfile,
                    widthPixels: 350
                })
            ]
        });

        if (this.voucherPermission === true) {
            this.voucherPermissionItems = [
                { label: this.translationService.getText('CustomerProfiles.Edit.BonusPrograms.Discount'), value: true }
            ];
        }

        this.voucherPermissionItems.push(
            { label: this.translationService.getText('CustomerProfiles.Edit.BonusPrograms.Cashback'), value: false }
        );
    }

    onItemSet(): void {
        const customerDiscounts = this.item().customerBonusPrograms.filter(c => !!c.paymentVoucherTypeId);
        const customerCashbacks = this.item().customerBonusPrograms.filter(c => !c.paymentVoucherTypeId && c.requiredBonusPoints);
        const customerCashbacksInPercent = this.item().customerBonusPrograms.filter(c => !c.paymentVoucherTypeId && !c.requiredBonusPoints)
            .map(cp => ({
                ...cp,
                bonusValue: cp.bonusValue?.replace('%', '')
            }));

        this.formGroup.patchValue({ ...this.item(), customerDiscounts, customerCashbacks, customerCashbacksInPercent });

        if (customerCashbacks.length > 0) {
            this.formGroup.controls.isDiscount.setValue(false);
        }

        if (customerCashbacksInPercent.length > 0) {
            this.formGroup.controls.isDiscount.setValue(false);
            this.formGroup.controls.cashbackInPercent.setValue(true);
        }
    }

    override prepareSaveModel(): Partial<CustomerProfileModel> {
        const value = this.formGroup.value;
        let customerBonusPrograms: OptionalType<CustomerBonusProgramModel[]>;

        if (value.isDiscount) {
            customerBonusPrograms = value.customerDiscounts;
        } else {
            if (value.cashbackInPercent) {
                customerBonusPrograms = value.customerCashbacksInPercent?.map(cashback => ({
                    ...cashback,
                    bonusValue: `${cashback?.bonusValue?.toString()}%`
                }));
            } else {
                customerBonusPrograms = value.customerCashbacks?.map(cashback => ({
                    ...cashback,
                    bonusValue: cashback?.bonusValue?.toString()
                }));
            }
        }

        return {
            noRemainingBonusPoints: value.noRemainingBonusPoints,
            bonusFactor: value.bonusFactor,
            customerBonusPrograms
        };
    }

    override onBrokenRulesLoad(): string[] {
        const mappedBrokenRuleIds: string[] = [];
        mappedBrokenRuleIds.push(...this.discountTableConfig.setBrokenRulesOnFormGroups(this.brokenRules));
        mappedBrokenRuleIds.push(...this.cashbackTableConfig.setBrokenRulesOnFormGroups(this.brokenRules));
        mappedBrokenRuleIds.push(...this.cashbackInPercentTableConfig.setBrokenRulesOnFormGroups(this.brokenRules));

        return mappedBrokenRuleIds;
    }

    /* eslint-disable no-null/no-null */
    private uniqueBonusPointsValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const requiredBonusPoints = control.get('requiredBonusPoints');

            if (!requiredBonusPoints) {
                return null;
            }

            const id = control.get('id')!;
            const newBonusPointValue = requiredBonusPoints.value;
            const isExistsInDiscount = this.formGroup.value.isDiscount
                && this.formGroup.value.customerDiscounts?.find(
                    d => d.requiredBonusPoints === newBonusPointValue && d.id !== id.value
                );
            const isExistsInCashback = !this.formGroup.value.isDiscount
                && this.formGroup.value.customerCashbacks?.find(
                    c => c.requiredBonusPoints === newBonusPointValue && c.id !== id.value
                );

            if (isExistsInCashback || isExistsInDiscount) {
                requiredBonusPoints.setErrors({ customError: { key: 'CustomerProfiles.Edit.BonusPrograms.BonusPointsShouldBeUnique' } });
                return { hasError: true };
            }

            if (!!requiredBonusPoints.errors) {
                delete requiredBonusPoints.errors['customError'];
                requiredBonusPoints.updateValueAndValidity({ onlySelf: true });
            }

            return null;
        };
    }

    private requireBonusFactorIfAnyBonusPointsValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isDiscount = control.get('isDiscount')!.value;
            const bonusFactor = control.get('bonusFactor')!;

            if (!isDiscount) {
                if (!!bonusFactor.errors) {
                    delete bonusFactor.errors['customError'];
                    bonusFactor.updateValueAndValidity({ onlySelf: true });
                }

                return null;
            }

            const bonusFactorValue = bonusFactor.value;
            const customerDiscounts: CustomerBonusProgramModel[] = control.get('customerDiscounts')!.value;

            if (customerDiscounts.some(d => !!d.requiredBonusPoints) && !bonusFactorValue) {
                control.get('bonusFactor')?.setErrors(
                    { customError: { key: 'CustomerProfiles.Edit.BonusPrograms.BonusFactorRequiredIfBonusPointsSet' } }
                );
                return { hasError: true };
            }

            if (!!bonusFactor.errors) {
                delete bonusFactor.errors['customError'];
                bonusFactor.updateValueAndValidity({ onlySelf: true });
            }

            return null;
        };
    }
    /* eslint-enable no-null/no-null */
}
