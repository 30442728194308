<div class="w-full" [ngClass]="{ 'border border-solid border-error rounded-md p-2': !!businessError() && formControl?.touched  }">
    <div class="w-full overflow-x-auto" *ngIf="!!config.columns && config.columns.length > 0; else cards">
        <table mat-table [dataSource]="dataSource" matSort class="w-full relative !table-fixed">
            <!-- dynamic columns -->
            <ng-container *ngFor="let columnModel of config.columns" [matColumnDef]="columnModel.identifier"
                [style.height.px]="config.rowHeightPx">
                <th mat-header-cell *matHeaderCellDef class="!pl-0 pb-0 !pr-[{{rowGapPx}}px] text-sm text-dark-gray" [style.width.px]="columnModel.widthPixels"
                    [style.min-width.px]="columnModel.widthPixels" [style.max-width.px]="columnModel.maxWidthPixels">
                    @if (!columnModel.shouldHideColumnTitle) {
                        <span class="flex items-center">
                            <span [arcPortalTooltip]="columnModel.columnTitleKey" class="flex items-center">
                                @if (shouldAllowSort && columnModel.allowSort && !!(columnModel.propertyName || columnModel.sortColumn) && !columnModel.shouldHideColumnTitle) {
                                    <span class="text-sm" [mat-sort-header]="(columnModel.sortColumn || columnModel.propertyName)!">
                                        @if (!columnModel.titleSignal) {
                                            {{ columnModel.columnTitleKey | transloco }}
                                            {{ !!columnModel.columnTitleSuffix ? ' ' + columnModel.columnTitleSuffix : '' }}
                                        } @else {
                                            {{ columnModel.titleSignal() }}
                                        }
                                    </span>
                                } @else {
                                    <span class="text-sm overflow-hidden overflow-ellipsis">
                                        {{ columnModel.shouldHideColumnTitle ? '' : (columnModel.columnTitleKey | transloco) }}
                                    </span>
                                }
                            </span>
                            @if (!!columnModel.canFilterEditableColumn) {
                                @if (openedSearchBox !== columnModel.columnTitleKey) {
                                    <button class="scale-75" mat-icon-button (click)="toggleColumnSearchStatus(columnModel)">
                                        <mat-icon class="!fg-element-selected">filter_alt</mat-icon>
                                    </button>
                                } @else {
                                    <span>
                                        <mat-chip-set>
                                            <mat-chip class="!bg-dark-gray">
                                                <div class="flex filter-wrapper" (keydown)="$event.stopPropagation()">
                                                    <div class="flex">
                                                        <mat-icon class="scale-75 ml-[-10px] !overflow-visible">filter_alt</mat-icon>
                                                        <input class="inner-table-filter-field" type="text" (input)="filterColumn(columnModel, $event.target || undefined)" autofocus />
                                                        <mat-icon class="scale-75 cursor-pointer mr-[-10px] !overflow-visible" 
                                                            (click)="toggleColumnSearchStatus(columnModel)">cancel</mat-icon>
                                                    </div>
                                                </div>
                                            </mat-chip>
                                        </mat-chip-set>
                                    </span>
                                }
                            }

                            @if (!!columnModel.titleActionIcon && !!columnModel.titleAction) {
                                <button class="scale-75" mat-icon-button (click)="columnModel.titleAction()">
                                    <mat-icon class="!fg-element-selected">{{columnModel.titleActionIcon}}</mat-icon>
                                </button>
                            }
                        </span>
                    }
                </th>
                <td mat-cell *matCellDef="let formGroup; let i = index" class="!pl-0 !pr-[{{rowGapPx}}px] whitespace-nowrap"
                    [style.height.px]="config.rowHeightPx" [style.width.px]="columnModel.widthPixels"
                    [style.min-width.px]="columnModel.widthPixels" [style.max-width.px]="columnModel.maxWidthPixels">
                    <div class="w-full h-full py-2 flex items-center">
                        <ng-container arcDynamicColumn [columnModel]="columnModel" [item]="formGroup.value" [formGroup]="formGroup">
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <!-- delete button -->
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef class="!p-0">
                    <button type="button" mat-icon-button (click)="deleteAll()" [disabled]="value.length === 0">
                        <mat-icon>close</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let formGroup; let i = index" class="!p-0">
                    @if (canDeleteRow(formGroup)) {
                        <button class="ml-auto" type="button" mat-icon-button (click)="deleteRow(i)" [disabled]="!canDeleteRow(formGroup)">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [style.height.px]="config.rowHeightPx">
            </tr>
            <tr mat-row *matRowDef="let formGroup; columns: displayedColumns" [style.height.px]="config.rowHeightPx">
            </tr>

            <tr *matNoDataRow>
                <td [attr.colspan]="displayedColumns.length" class="p-4 text-center">
                    {{ 'Components.EditableTable.NoRows' | transloco }}
                </td>
            </tr>
        </table>
    </div>

    <ng-template #cards>
        <div class="w-full flex flex-col gap-4">
            <mat-card [formGroup]="formGroup" *ngFor="let formGroup of dataSource.filteredData; let i = index"
                class="w-full !border-2 !border-app !border-solid">
                <mat-card-content class="h-full">
                    <div class="flex flex-nowrap">
                        <ng-container *ngTemplateOutlet="template; context: { $implicit: formGroup, index: i }">
                        </ng-container>
                        <div class="flex items-center">
                            <button type="button" mat-icon-button (click)="deleteRow(i)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </ng-template>

    <div *ngIf="config.showPaging" class="w-full overflow-x-auto">
        <mat-paginator [length]="dataSource.data.length" [pageSize]="config.pageSize"></mat-paginator>
    </div>

    <div class="w-full flex justify-end gap-4 mt-4">
        <ng-container *ngFor="let btn of config.additionalButtons">
            <arc-button [arcPortalTooltip]="btn.labelKey" type="stroked" mat-stroked-button [isDisabled]="btn.isDisabled"
                [matTooltip]="!!btn.tooltipKey ? (btn.tooltipKey | transloco) : ''" matBadge="?" matBadgeSize="small"
                [matBadgeHidden]="!btn.tooltipKey" (click)="handleAdditionalAddButtonClicked(btn)"
                [isLoading]="additionalButtonsLoading[btn.labelKey]">
                {{ btn.labelKey| transloco }}
            </arc-button>
        </ng-container>
        <button *ngIf="!config.shouldHideAddButton" type="button" mat-stroked-button (click)="addRow()">
            {{ 'General.Actions.Add' | transloco }}
        </button>
    </div>
</div>
@if (!!businessError() && formControl?.touched) {
    <p class="mt-2 text-center text-error">
        {{ businessError() | errorTranslator | async }}
    </p>
}