import { Component, computed, HostBinding, inject, OnInit, signal } from '@angular/core';
import { finalize } from 'rxjs';

import { BaseSidebarComponent } from '../../../components/sidebar-components/base-sidebar/base-sidebar.component';
import { TicketListModel } from '../../models/responses/ticket-list.model';
import { TicketStore } from '../../services/stores/ticket.store';
import { SidebarHeaderService } from '../../../core/services/sidebar-header.service';
import { TranslationService } from '../../../core/services/translation.service';
import { TicketService } from '../../../core/services/ticket.service';
import { TicketStatusEnum } from '../../models/enums/ticket-status.enum';

@Component({
    selector: 'arc-tickets',
    templateUrl: './tickets.component.html',
    styleUrl: './tickets.component.scss'
})
export class TicketsComponent extends BaseSidebarComponent implements OnInit {
    @HostBinding('class') classes = 'h-full flex flex-col smd:px-2 smd:pt-2';

    isLoading = signal(true);
    selectedTicket = computed(() => this.ticketService.selectedTicket());
    allOpenTickets = signal<TicketListModel[]>([]);
    actionRequiredTickets = computed(() =>
        this.allOpenTickets().filter(
            ticket => ticket.status === TicketStatusEnum.WaitingOnRequester || ticket.status === TicketStatusEnum.ResolveSuggested
        )
    );

    ticketsList = computed(() => (this.ticketService.showOnlyActionRequired() ? this.actionRequiredTickets() : this.allOpenTickets()));
    hasOpenTickets = computed(() => this.ticketService.hasOpenTickets());

    readonly ticketService = inject(TicketService);
    readonly translationService = inject(TranslationService);

    private readonly headerService = inject(SidebarHeaderService);
    private readonly ticketStore = inject(TicketStore);

    constructor() {
        super();

        this.headerService.hasPadding.set(false);
        this.addSubscriptions(this.headerService.backButtonClicked.subscribe(() => this.backToStart()));
    }

    ngOnInit(): void {
        this.loadTickets();
    }

    loadTickets(): void {
        this.isLoading.set(true);

        this.ticketStore
            .getOpenTickets()
            .pipe(
                finalize(() => {
                    this.isLoading.set(false);
                })
            )
            .subscribe(response => {
                this.allOpenTickets.set(response?.value || []);
            });
    }

    selectTicket(ticketId: number): void {
        this.isLoading.set(true);

        this.ticketService
            .loadTicket(ticketId)
            .pipe(
                finalize(() => {
                    this.isLoading.set(false);
                })
            )
            .subscribe();
    }

    backToStart(): void {
        this.ticketService.selectedTicket.set(undefined);
    }
}
