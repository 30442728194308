@if(isLoading()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32" />
    </div>
} @else {
    @if(!isCreatingTicket() && !selectedArticle()) {
        <div class="mb-10">
            <input class="w-full h-16 bg-element border-solid border border-on-app-light rounded-md p-5" type="text"
                [matAutocomplete]="searchAutocomplete" (input)="onSearch($event)" [placeholder]="'Help.SearchPlaceholder' | transloco" />
            <mat-autocomplete #searchAutocomplete="matAutocomplete" class="!p-0" hideSingleSelectionIndicator="true"
                (optionSelected)="onSearchResultSelected($event)">
                <mat-option *ngFor="let item of searchResults()" [value]="item" class="!p-0">
                    <div class="flex flex-row p-3 group hover:bg-element-hover hover:cursor-pointer">
                        <div>
                            <arc-icon [icon]="item.helpSubTopicIcon || item.helpTopicIcon || 'help'" [size]="40" class="flex-none" />
                        </div>
                        <div class="flex-1 flex flex-col">
                            <div class="font-bold">
                                {{ item.title }}
                            </div>
                            <div>
                                {{ item.summary }}
                            </div>
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>
    }
    @if (isHelpArticleView() || !!selectedArticle()) {
        <arc-selected-article [selectedArticle]="selectedArticle()!" />
    }
    @else if (isCreatingTicket()) {
        <arc-new-ticket />
    }
    @else {
        <div class="grow">
            @if (recommendedArticles().length > 0) {
                <h2 class="text-accent font-bold">{{ 'Help.RecommendedArticles' | transloco }}</h2>
                <div class="flex flex-col gap-4 mt-8 mb-10">
                    @for(article of recommendedArticles(); track article.id) {
                        <arc-article-list-item [article]="article" (articleSelected)="selectArticle($event)" />
                    }
                </div>
            }
            @if (otherArticles().length > 0) {
                <h2 class="text-accent font-bold">{{'Help.MoreArticles' | transloco}}</h2>
                <div class="flex flex-col gap-4 mt-8 mb-10">
                    @for(article of otherArticles(); track article.id) {
                        <arc-article-list-item [article]="article" (articleSelected)="selectArticle($event)" />
                    }
                </div>
            }
        </div>
        <div class="flex items-center justify-between mt-4 py-8 smd:py-4 shadow-[rgba(0,0,15,0.5)_1px_-6px_9px_-11px] z-[200]">
            <arc-button type="flat" color="accent" (clicked)="createTicket()" class="ml-auto">
                {{ 'Tickets.CreateTicket' | transloco }}
            </arc-button>
        </div>
    }
}
