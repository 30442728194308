import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, of, tap } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { WarehouseRequestListModel } from '../../../models/responses/warehouse-request-list.model';
import { WarehouseRequestModel } from '../../../models/warehouse-request.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { WarehouseRequestsStore } from '../../../services/stores/warehouse-requests.store';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { WarehouseTranserCreateDialogComponent } from './warehouse-transer-create-dialog/warehouse-transer-create-dialog.component';
import { WarehouseTransferEditBaseDataComponent } from './edit-items/warehouse-transfer-edit-base-data/warehouse-transfer-edit-base-data.component';
import { WarehouseTransferEditArticlesComponent } from './edit-items/warehouse-transfer-edit-articles/warehouse-transfer-edit-articles.component';
import { WarehouseRequestCreateModel } from '../../../models/requests/warehouse-request-create.model';
import { WarehouseRequestEditModel } from '../../../models/requests/warehouse-request-edit.model';
import { WarehouseTransferEditHeaderAdditionalInfoComponent } from './warehouse-transfer-edit-header-additional-info/warehouse-transfer-edit-header-additional-info.component';

@Component({
    selector: 'arc-warehouse-transfers',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./warehouse-transfers.component.scss']
})
/* eslint-disable @typescript-eslint/indent */
export class WarehouseTransfersComponent extends PageListViewComponent<
    WarehouseRequestListModel,
    WarehouseRequestModel,
    WarehouseRequestCreateModel,
    WarehouseRequestEditModel
> {
    /* eslint-enable @typescript-eslint/indent */
    private readonly warehouseRequestsStore = inject(WarehouseRequestsStore);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super('WarehouseRequests');

        /* eslint-disable @typescript-eslint/indent */
        this.config = new TableListViewConfigModel<
            WarehouseRequestListModel,
            WarehouseRequestModel,
            WarehouseRequestCreateModel,
            WarehouseRequestEditModel
        >({
            /* eslint-enable @typescript-eslint/indent */
            entityName: 'WarehouseTransfers',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'WarehouseTransfers.List.WarehouseTransfer',
                    propertyName: 'transfer',
                    propertyName2: 'id',
                    widthPixels: 236,
                    allowSort: true,
                    sortColumn: 'id',
                    customFormatter: (record, value, propertyName) =>
                        propertyName !== 'transfer' ? value : `${record.deliveringStoreName} > ${record.receivingStoreName}`
                }),
                fromWarehouse: new StringColumnModel({
                    columnTitleKey: 'WarehouseTransfers.List.FromWarehouse',
                    propertyName: 'deliveringStoreName',
                    widthPixels: 200
                }),
                toWarehouse: new StringColumnModel({
                    columnTitleKey: 'WarehouseTransfers.List.ToWarehouse',
                    propertyName: 'receivingStoreName',
                    widthPixels: 200
                }),
                status: new GenericStatusColumnModel({
                    columnTitleKey: 'General.Status',
                    propertyName: 'status',
                    widthPixels: 160
                }),
                dateRequested: new DateColumnModel({
                    columnTitleKey: 'WarehouseTransfers.List.CreatedOn',
                    propertyName: 'dateRequested',
                    widthPixels: 160,
                    format: 'short'
                }),
                dateDelivered: new DateColumnModel({
                    columnTitleKey: 'WarehouseTransfers.List.SentOn',
                    propertyName: 'dateDelivered',
                    widthPixels: 160,
                    format: 'short'
                }),
                dateReceived: new DateColumnModel({
                    columnTitleKey: 'WarehouseTransfers.List.DeliveredOn',
                    propertyName: 'dateReceived',
                    widthPixels: 160,
                    format: 'short'
                }),
                articlesCount: new StringColumnModel({
                    columnTitleKey: 'WarehouseTransfers.List.ArticlesCount',
                    propertyName: 'articlesCount',
                    widthPixels: 126
                })
            },
            defaultSort: new ColumnSortModel({ column: 'status', direction: SortDirectionEnum.Asc }),
            defaultColumnOrder: [
                'title',
                'fromWarehouse',
                'toWarehouse',
                'status',
                'dateRequested',
                'dateDelivered',
                'dateReceived',
                'articlesCount'
            ],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            store: this.warehouseRequestsStore,
            handleCreateButtonClick: this.handleCreate.bind(this),
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'WarehouseTransfers.Edit.BaseData.ComponentTitle', component: WarehouseTransferEditBaseDataComponent },
                    { titleKey: 'WarehouseTransfers.Edit.Articles.ComponentTitle', component: WarehouseTransferEditArticlesComponent }
                ],
                customMaxWidth: '1200px',
                headerSubtitle: current => of(`${current.deliveringStoreName} > ${current.receivingStoreName}`),
                headerAdditionalInfoComponent: WarehouseTransferEditHeaderAdditionalInfoComponent
            }
        });
    }

    private handleCreate(): Observable<boolean> {
        return this.matDialog
            .open(WarehouseTranserCreateDialogComponent, {
                maxHeight: '98svh',
                maxWidth: '98vw',
                disableClose: true
            })
            .afterClosed()
            .pipe(
                tap<any>(result => {
                    if (!result?.id) {
                        return;
                    }
                    this.listView.openEditSidebar(result.id).subscribe(shouldReload => {
                        if (shouldReload) {
                            this.listView.refresh();
                        }
                    });
                }),
                map(result => result?.shouldReload ?? false)
            );
    }
}
