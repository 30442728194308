import { Directive, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

import { BaseColumnModel } from '../../models/column-types/base-column.model';
import { BaseComponent } from '../../../abstractions/base.component';
import { CustomColumnModel } from '../../models/column-types/custom-column.model';

/**
 * This is the base class for all components that can be used inside a table as a custom column.
 */
@Directive()
export class BaseCustomColumnComponent<T extends object, TModel extends BaseColumnModel = CustomColumnModel<T>> extends BaseComponent {
    @Input() item!: T;
    @Input() formGroup!: FormGroup<{ [K in keyof T]: AbstractControl<any, any> }>;
    @Input() columnModel!: TModel;
}
