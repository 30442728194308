<div class="size-full grid place-items-center">
    <arc-icon icon="query_stats" [size]="24" class="hover:text-accent transition-colors" (mouseenter)="show()" (mouseleave)="hide()" #icon>
    </arc-icon>
</div>

<!-- tooltip overlay template -->
<ng-template cdkPortal>
    <div class="arc-shadow bg-element-selected text-on-app px-3 py-1 rounded text-sm origin-top" @tooltipAnimation>
        @if (performanceData(); as performanceData) {
            @if (performanceData.length > 0 && totalSoldQuantity() > 0){
                <div class="grid gap-x-6"
                    [ngClass]="performanceData[0].quantitySoldBranch !== undefined ? 'grid-cols-[auto_auto_auto]' : 'grid-cols-[auto_auto]'">
                    <!-- month (title) -->
                    <div class="font-bold">
                        {{ 'Orders.Edit.Articles.ArticleSalesHistory.Sales' | transloco }}
                    </div>

                    <!-- branch (title) -->
                    @if(performanceData[0].quantitySoldBranch !== undefined) {
                        <div class="font-bold">
                            {{ 'Orders.Edit.Articles.ArticleSalesHistory.Branch' | transloco }}
                        </div>
                    }
                    <!-- total (title) -->
                    <div class="font-bold">
                        {{ 'Orders.Edit.Articles.ArticleSalesHistory.Total' | transloco }}
                    </div>
                    @for(entry of performanceData; track entry.month) {
                        <!-- month -->
                        <div class="border-b border-black">{{ entry.month | arcDate : 'MMM YY' }}</div>

                        <!-- branch -->
                        @if(performanceData[0].quantitySoldBranch !== undefined) {
                            <div class="border-b border-black text-right">
                                {{ entry.quantitySoldBranch !== 0 ? (entry.quantitySoldBranch | arcNumber) : '-' }}
                            </div>
                        }

                        <!-- total -->
                        <div class="border-b border-black text-right">
                            {{ entry.quantitySoldTotal !== 0 ? (entry.quantitySoldTotal | arcNumber) : '-' }}
                        </div>
                    }
                </div>
            } @else {
                <div>{{ 'Orders.Edit.Articles.ArticleSalesHistory.NoSalesLastYear' | transloco }}</div>
            }
        } @else {
            <div class="flex justify-center">
                <mat-spinner [diameter]="16" />
            </div>
        }
    </div>
</ng-template>
