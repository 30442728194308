import { Component, computed, inject, input } from '@angular/core';
import { formatDate } from '@angular/common';

import { TicketMessageModel } from '../../../../app/models/ticket-message.model';
import { Utils } from '../../../../core/utils/tools/utils.tools';
import { BlobModel } from '../../../../app/models/blob.model';
import { SecuritySessionStorage } from '../../../../core/services/storages/security.session-storage';
import { TranslationService } from '../../../../core/services/translation.service';
import { HelpService } from '../../../../core/services/help.service';
import { Tools } from '../../../../core/utils/tools';
import { EnvironmentService } from '../../../../core/services/environment.service';

@Component({
    selector: 'arc-ticket-message-item',
    templateUrl: './ticket-message-item.component.html',
    styleUrl: './ticket-message-item.component.scss'
})
export class TicketMessageItemComponent {
    readonly message = input.required<TicketMessageModel>();
    readonly ticketMessageOffers = computed(() => this.message().ticketMessageOffers || []);
    readonly ticketId = input.required<number>();
    readonly createdBy = computed(() => {
        const myEmail = this.securitySessionStorage.getUserInfo()?.email;
        return this.message().isMyResponse && this.message().createdByEmail === myEmail
            ? this.translationService.getText('Tickets.View.Me')
            : this.message().createdByEmail;
    });

    readonly createdByAvatar = computed(() => `${this.environmentService.portalUrl}/users/${this.message().createdByEmail}/avatar`);

    readonly createdAt = computed(() => {
        const now = new Date();
        const date = this.message().createdDate;
        const isToday = date.toDateString() === now.toDateString();
        const todayText = this.translationService.getText('Tickets.Today');

        if (isToday) {
            return `${todayText}, ${formatDate(date, 'HH:mm', 'de-CH')}`;
        } else {
            return formatDate(date, 'dd.MM.yy, HH:mm', 'de-CH');
        }
    });

    shouldUseWhiteFont = computed(() => Tools.Utils.hexColorShouldUseWhiteFont(this.message().backgroundColorHex));
    messageClasses = computed(() => {
        const message = this.message();
        const shouldUseWhiteFont = this.shouldUseWhiteFont();

        if (!!message.backgroundColorHex) {
            return shouldUseWhiteFont ? 'text-white' : 'text-black';
        }

        return message.isMyResponse ? 'bg-success-light text-on-success-light' : 'bg-element-selected text-on-app';
    });

    private readonly securitySessionStorage = inject(SecuritySessionStorage);
    private readonly translationService = inject(TranslationService);
    private readonly environmentService = inject(EnvironmentService);
    private readonly helpService = inject(HelpService);

    downloadFile(file: BlobModel): void {
        const blob = new Blob([new Uint8Array(file.blobData)], { type: file.fileMimeType });
        Utils.saveFile(blob, file.fileName ?? 'file.bin');
    }

    openHelpArticle(helpArticleId: number): void {
        this.helpService.openHelp(helpArticleId.toString());
    }
}
