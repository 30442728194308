/* eslint-disable @typescript-eslint/indent */
import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { RecordChangesFieldListModel } from '../../../models/record-changes-field-list.model';
import { RecordChangesStore } from '../../../services/stores/record-changes.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { RecordChangeTypeEnumExtensions } from '../../../models/enums/record-change-type.enum';

@Component({
    selector: 'arc-record-changes',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './record-changes.component.scss'
})
export class RecordChangesComponent extends PageListViewComponent<
    RecordChangesFieldListModel,
    RecordChangesFieldListModel,
    RecordChangesFieldListModel,
    RecordChangesFieldListModel,
    number
> {
    private readonly recordChangesStore = inject(RecordChangesStore);

    constructor() {
        super('RecordChangeField');
        this.config = new TableListViewConfigModel<
            RecordChangesFieldListModel,
            RecordChangesFieldListModel,
            RecordChangesFieldListModel,
            RecordChangesFieldListModel,
            number
        >({
            entityName: 'RecordChangeField',
            availableColumns: {
                changeType: new GenericStatusColumnModel({
                    columnTitleKey: 'RecordChanges.List.ChangeType',
                    propertyName: 'changeType',
                    statusColumn: 'changeType',
                    descriptionColumn: 'changeTypeDescription',
                    widthPixels: 150,
                    bgColor: value => RecordChangeTypeEnumExtensions.getColor(value)
                }),
                changeTime: new DateColumnModel({
                    columnTitleKey: 'RecordChanges.List.ChangeTime',
                    propertyName: 'changeTime',
                    format: 'short',
                    widthPixels: 150
                }),
                user: new StackedColumnModel({
                    columnTitleKey: 'RecordChanges.List.User',
                    propertyName: 'username',
                    propertyName2: 'proxySupporterEmail',
                    widthPixels: 150
                }),
                tableName: new StringColumnModel({
                    columnTitleKey: 'RecordChanges.List.TableName',
                    propertyName: 'tableName',
                    widthPixels: 200
                }),
                element: new StackedColumnModel({
                    columnTitleKey: 'RecordChanges.List.Element',
                    propertyName: 'element',
                    propertyName2: 'recordId',
                    widthPixels: 200
                }),
                fieldName: new StringColumnModel({
                    columnTitleKey: 'RecordChanges.List.FieldName',
                    propertyName: 'fieldName',
                    widthPixels: 200
                }),
                oldValue: new StringColumnModel({
                    columnTitleKey: 'RecordChanges.List.OldValue',
                    propertyName: 'oldValue',
                    widthPixels: 200,
                    shouldShowTooltip: true
                }),
                newValue: new StringColumnModel({
                    columnTitleKey: 'RecordChanges.List.NewValue',
                    propertyName: 'newValue',
                    widthPixels: 200,
                    shouldShowTooltip: true
                })
            },
            defaultSort: new ColumnSortModel({
                column: 'changeTime',
                direction: SortDirectionEnum.Desc
            }),
            defaultColumnOrder: ['changeType', 'changeTime', 'user', 'tableName', 'element', 'fieldName', 'oldValue', 'newValue'],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            store: this.recordChangesStore
        });
    }
}
/* eslint-enable @typescript-eslint/indent */
