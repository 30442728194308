<div class="flex items-start">
    <div class="flex-shrink-0 w-[40px] h-[40px] pt-0 pb-3 self-start"
        [ngClass]="message().isMyResponse ? 'order-last pr-0 pl-3' : 'order-first pr-3 pl-0'">
        @if(message().showAvatar){
            <img src="{{ createdByAvatar() }}" [matTooltip]="message().createdByEmail" alt="message().createdByEmail" />
        }
    </div>

    <div class="flex flex-col max-w-[75%] min-w-64" [ngClass]="message().isMyResponse ? 'order-first ml-auto' : 'order-last mr-auto'">
        <div class="px-4 pt-2 pb-2 rounded-lg" [style.background-color]="message().backgroundColorHex" [ngClass]="messageClasses()">
            <div class="arc-markdown" markdown>{{ message().text }}</div>
            <span class="text-xs float-right">
                {{ createdAt() }}
            </span>
        </div>

        <div class="flex flex-row" [ngClass]="message().isMyResponse ? 'self-end' : 'self-start' ">
            @if(!!message().devOpsItemId) {
                <div class="p-4 rounded-lg my-2 shadow bg-white" [ngClass]="messageClasses()">
                    <div class="text-xs">{{'Tickets.DevOpsInfo' | transloco}}</div>
                    <div class="flex items-center gap-2">
                        <mat-icon>{{message().devOpsItemIcon}}</mat-icon>
                        <span>#{{ message().devOpsItemId }}</span>
                        <mat-icon [style.color]="'#' + message().devOpsItemStatusColor">circle</mat-icon>
                        <span>{{ message().devOpsItemStatusDescription }}</span>
                    </div>
                </div>
                }

                @if(!!message().helpArticleId) {
                    <div class="p-4 rounded-lg my-2 shadow bg-white cursor-pointer" (click)="openHelpArticle(message().helpArticleId!)" [ngClass]="messageClasses()">
                        <div class="flex items-center gap-2">
                            <mat-icon>help_outline</mat-icon>
                            <span class="mr-4">{{ message().helpArticleTitle }}</span>
                            <mat-icon>chevron_right</mat-icon>
                        </div>
                    </div>
                }

                @for(attachment of message().attachments; track attachment.id){
                    <div class="p-4 rounded-lg my-4" [style.background-color]="message().backgroundColorHex" [ngClass]="messageClasses()">
                        <div class="flex items-center gap-2">
                            <mat-icon class="mr-2">insert_drive_file</mat-icon>
                            <span>{{ attachment.fileName }}</span>
                            <button class="ml-auto" mat-icon-button (click)="downloadFile(attachment)">
                                <mat-icon>download</mat-icon>
                            </button>
                        </div>
                    </div>
                }

                @if(ticketMessageOffers().length > 0){
                    <arc-ticket-message-offers [offers]="ticketMessageOffers()" [messageId]="message().id"
                        [ngClass]="messageClasses()"></arc-ticket-message-offers>
                }
        </div>
    </div>
</div>
