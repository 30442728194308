<h1 mat-dialog-title>{{ dialogTitleKey | transloco }}</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <arc-search-filter [store]="store"></arc-search-filter>

    <div class="relative min-h-[128px]">
        <table class="w-full !table-fixed" mat-table [dataSource]="data()" matSort (matSortChange)="handleSort($event)">
            <!-- selection -->
            <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef class="w-[64px]">
                    <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selectionModel.hasValue() && isEverythingSelected()"
                        [indeterminate]="selectionModel.hasValue() && !isEverythingSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let entry" class="w-[64px]">
                    <mat-checkbox (change)="itemSelectionChanged(entry)" [checked]="selectionModel.isSelected(entry)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- columns -->
            @for(column of columnConfig; track column.propertyName) {
                <ng-container [matColumnDef]="column.propertyName!">
                    <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.propertyName!" [style.min-width.px]="column.widthPixels"
                        [style.width.px]="column.widthPixels" ngClass="arc-header-{{column.columnTitleAlignment}} !break-words !whitespace-normal hyphens-auto">
                        {{ column.columnTitleKey| transloco }}
                    </th>
                    <td mat-cell *matCellDef="let entry" class="!break-words !whitespace-normal hyphens-auto" [style.min-width.px]="column.widthPixels"
                        [style.width.px]="column.widthPixels">
                        <ng-container arcDynamicColumn [columnModel]="column" [item]="entry"></ng-container>
                    </td>
                </ng-container>
            }

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let entry; columns: displayedColumns;"
                [ngClass]="{ 'cursor-pointer hover:!bg-element-hover': !_dialogData.isMultiSelect }" (click)="select(entry)"></tr>
        </table>

        @if(isLoading()){
            <div class="absolute inset-0 grid bg-overlay place-items-center">
                <mat-spinner [diameter]="64"></mat-spinner>
            </div>
        }
    </div>

    <mat-paginator class="sticky -bottom-[21px] z-[10]" [length]="totalRecords" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
        (page)="handlePageEvent($event)"></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions [class.data-selection-spaced-actions]="_dialogData.shouldDisplaySelectAllButton">
    <div class="mr-2">
        <button mat-stroked-button [mat-dialog-close]="undefined">{{ 'General.Actions.Cancel' | transloco }}</button>
    </div>

    <div>
        @if (_dialogData.shouldDisplaySelectAllButton) {
            <button mat-stroked-button [disabled]="data().length === 0" (click)="loadAndSelectAll()">
                {{ 'Components.DataSelectionDialog.SelectAllX' | transloco:{ amount:totalRecords} }}
            </button>
        }

        @if (_dialogData.isMultiSelect) {
            <button mat-flat-button color="accent" [disabled]="!selectionModel.hasValue()" [mat-dialog-close]="selectionModel.selected()">
                {{ 'Components.DataSelectionDialog.AddX' | transloco:{ amount:selectionModel.selected().length} }}
            </button>
        }
    </div>
</mat-dialog-actions>
