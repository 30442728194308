import { Component, computed, inject, input } from '@angular/core';

import { TicketMessageOffer } from '../../../../app/models/ticket-message-offer.model';
import { TicketService } from '../../../../core/services/ticket.service';
import { TicketStore } from '../../../../app/services/stores/ticket.store';

@Component({
    selector: 'arc-ticket-message-offers',
    templateUrl: './ticket-message-offers.component.html',
    styleUrl: './ticket-message-offers.component.scss'
})
export class TicketMessageOffersComponent {
    readonly offers = input.required<TicketMessageOffer[]>();
    readonly messageId = input.required<number>();

    readonly canDeclineOffers = computed(
        () =>
            // eslint-disable-next-line no-null/no-null
            this.offers().length > 0 && this.offers().every(offer => offer.accepted === null || offer.accepted === undefined)
    );

    private readonly ticketStore = inject(TicketStore);
    private readonly ticketService = inject(TicketService);

    acceptOffer(offerId: number): void {
        this.ticketStore.acceptOffer(offerId).subscribe(() => {
            this.ticketService.reloadTicket();
        });
    }

    declineOffer(ticketMessageId: number): void {
        this.ticketStore.declineOffers(ticketMessageId).subscribe(() => {
            this.ticketService.reloadTicket();
        });
    }
}
