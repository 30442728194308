import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { OrderModel } from '../../models/order.model';
import { OrderListModel } from '../../models/responses/order-list.model';
import { OrderCreateModel } from '../../models/requests/order-create.model';
import { OrderUpdateModel } from '../../models/requests/order-update.model';
import { OrderArticleModel } from '../../models/order-article.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { OrderArticleSalesQuantitiesModel } from '../../models/responses/order-article-sales-quantities.model';

@Injectable({
    providedIn: 'root'
})
export class OrdersStore extends BaseCrudStore<OrderModel, OrderListModel, OrderCreateModel, OrderUpdateModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'orders' }));
    }

    calculateArticlesForOrder(orderId: number, fillAllToTarget: boolean): Observable<ApiResponseModel<OrderArticleModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<OrderArticleModel[]>>(
            this.getUrl(`${orderId}/calculate-articles`),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'fillAllToTarget', value: fillAllToTarget.toString() }
        );
    }

    getArticleSalesQuantities(orderId: number, articleId: number): Observable<ApiResponseModel<OrderArticleSalesQuantitiesModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<OrderArticleSalesQuantitiesModel[]>>(
            this.getUrl(`${orderId}/sales-quantities/${articleId}`)
        );
    }
}
