<div class="flex">
    @for(star of stars; track $index; let i = $index){
        <arc-icon [size]="size()" class="cursor-pointer text-accent"
            [class.!text-accent]="rating() >= i + 1 || star" 
            (click)="onClick(i + 1)" [icon]="star ? 'star': 'star_outline'"
            (mouseover)="onMouseOver(i + 1)" 
            (mouseleave)="onMouseLeave()">
        </arc-icon>
    }
</div>
