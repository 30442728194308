@for(offer of offers(); track offer.id){
    @if(!offer.isExpired){
        <div class="p-2 rounded-lg my-2 shadow bg-white flex flex-row gap-8 items-center">
            @if(offer.accepted === null || offer.accepted === undefined){
                @if(offer.amount > 0){
                    <div>
                        <div class="text-xs">{{ offer.currency }}</div>
                        <div class="text-base">{{ offer.amount | arcNumber: 2 }}</div>
                    </div>
                }

                @if(offer.poolTime > 0){
                    <div>
                        <div class="text-xs">{{ 'Tickets.TicketMessageOffer.Time' | transloco}}
                        </div>
                        <div class="text-base">{{ offer.poolTime }}</div>
                    </div>
                }
                <div>
                    <div class="text-xs">
                        {{ 'Tickets.TicketMessageOffer.DoneUntil' | transloco }}
                    </div>
                    <div class="text-base">{{ offer.doneUntil | arcDate: 'shortDate' }}</div>
                </div>
                <div class="ml-auto">
                    <arc-button type="flat" color="accent" (clicked)="acceptOffer(offer.id)">
                        <arc-icon icon="check" [size]="36" class="text-white"></arc-icon>
                    </arc-button>
                </div>
            }
            @else if(offer.accepted === true){
                @if(offer.amount > 0){
                    <div>
                        <div class="text-xs">{{ offer.currency }}</div>
                        <div class="text-base">{{ offer.amount | arcNumber: 2 }}</div>
                    </div>
                }
                <div>
                    <div class="text-xs">
                        {{ 'Tickets.TicketMessageOffer.DoneUntil' | transloco }}
                    </div>
                    <div class="text-base">{{ offer.doneUntil | arcDate: 'shortDate' }}</div>
                </div>
                <div class="ml-auto">
                    <arc-icon icon="check" [size]="36" class="text-accent"></arc-icon>
                </div>

            }
            @else if (offer.accepted === false) {
                @if(offer.amount > 0){
                    <div>
                        <div class="text-xs">{{ offer.currency }}</div>
                        <div class="text-base">{{ offer.amount | arcNumber: 2 }}</div>
                    </div>
                }
                <div>{{ 'Tickets.TicketMessageOffer.OfferDeclined' | transloco }}</div>
            }
        </div>
    }
    @else{
        @if(offer.amount > 0){
            <div>
                <div class="text-sm">{{ offer.currency }}</div>
                <div class="text-lg">{{ offer.amount | arcNumber: 2 }}</div>
            </div>
        }
        <div>{{ 'Tickets.TicketMessageOffer.OfferExpired' | transloco }}</div>
    }
}
@if(canDeclineOffers()){
    <div class="p-2 rounded-lg my-2 shadow bg-white flex justify-center items-center">
        <button mat-button (click)="declineOffer(messageId())">
            {{ 'Tickets.TicketMessageOffer.DeclineOffer' | transloco }}
            <mat-icon color="warn">close</mat-icon>
        </button>
    </div>
}