<h1 mat-dialog-title class="!font-normal !text-2xl !m-6">{{ 'Tickets.Rating.DialogTitle' | transloco }}</h1>
<mat-dialog-content class="!m-5">
    <div class="flex justify-center !mt-5 !mb-5">
        <arc-rating [size]="50" (ratingUpdated)="onRatingUpdated($event)"></arc-rating>
    </div>
    @if(shouldGiveRatingReason()){
        <div class="my-10 mx-5">
            <div>
                <h2 class="!font-normal !text-xl my-6">{{ 'Tickets.Rating.RatingReasonMessage' | transloco }}</h2>
            </div>
            <mat-button-toggle-group class="mt-3" [(value)]="ratingReason">
                <mat-button-toggle [value]="RatingReasonsEnum.FasterAnswer">{{ 'Enums.RatingReasonsEnum.FasterAnswer' | transloco }}
                </mat-button-toggle>
                <mat-button-toggle [value]="RatingReasonsEnum.BetterSolution">{{ 'Enums.RatingReasonsEnum.BetterSolution' | transloco }}
                </mat-button-toggle>
                <mat-button-toggle
                    [value]="RatingReasonsEnum.ClearerCommunication">{{ 'Enums.RatingReasonsEnum.ClearerCommunication' | transloco }}
                </mat-button-toggle>
                <mat-button-toggle [value]="RatingReasonsEnum.Friendlier">{{ 'Enums.RatingReasonsEnum.Friendlier' | transloco }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    }

</mat-dialog-content>
<mat-dialog-actions class="!m-5">
    <arc-button type="stroked" (clicked)="close(false)">{{ 'Tickets.Rating.SkipButton' | transloco }}</arc-button>
    <arc-button type="flat" color="accent" (clicked)="close(true)">{{ 'Tickets.Rating.RateButton' | transloco }}</arc-button>
</mat-dialog-actions>
