import { ArticleSupplierListModel } from '../../../../app/models/responses/article-supplier-list.model';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { CurrencyColumnModel } from '../../../dynamic-table/models/column-types/currency-column.model';
import { NumberColumnModel } from '../../../dynamic-table/models/column-types/number-column.model';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../dynamic-table/models/column-types/string-column.model';

export const articleSupplierSelectionTableConfig: BaseColumnModel[] = [
    new StackedColumnModel({
        propertyName: 'articleTitle',
        propertyName2: 'articleArticleNumber',
        columnTitleKey: 'ArticleSuppliers.List.Article',
        widthPixels: 260
    }),
    new StringColumnModel({
        propertyName: 'orderNumber',
        columnTitleKey: 'ArticleSuppliers.List.OrderNumber',
        widthPixels: 160
    }),
    new CurrencyColumnModel({
        propertyName: 'buyingPrice',
        columnTitleKey: 'ArticleSuppliers.List.BuyingPrice',
        widthPixels: 145
    }),
    new NumberColumnModel({
        propertyName: 'minOrderQuantity',
        unit: (record: ArticleSupplierListModel) => record.articleUnitTitle,
        columnTitleKey: 'ArticleSuppliers.List.MinOrderQuantity'
    })
];
