import { Component, forwardRef, inject, input, output, viewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, debounceTime, map, switchMap, tap } from 'rxjs';

import { SearchRequestModel } from '../../../app/models/requests/search-request.model';
import { OptionalType } from '../../../core/models/types/optional.type';
import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { DataSelectionDialogComponent } from '../../dialogs/data-selection-dialog/data-selection-dialog.component';
import { QuickSearchConfigModel } from './models/quick-search-config.model';
import { Identifyable } from '../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-quick-search',
    templateUrl: './quick-search.component.html',
    styleUrls: ['./quick-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuickSearchComponent),
            multi: true
        }
    ]
})
export class QuickSearchComponent<TList extends Identifyable<TId>, TId = number> extends BaseControlValueAccessor<TId> {
    readonly autocomplete = viewChild.required(MatAutocomplete);
    readonly config = input.required<QuickSearchConfigModel<TList, TId>>();
    readonly optionSelected = output<TList>();
    readonly inputChanged = output<string>();

    isLoading = false;
    isInvalidInput = false;
    options: TList[] = [];

    protected readonly _debounceTimeMs = 250;
    protected readonly _searchResultSize = 50;

    private currentSelection?: TList;

    private readonly internalSearchSubject = new Subject<string>();
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();

        this.internalSearchSubject
            .pipe(
                tap(() => {
                    this.options = [];
                    this.isLoading = true;
                    this.isInvalidInput = true;
                }),
                debounceTime(this._debounceTimeMs),
                switchMap(searchText =>
                    this.config().store
                        .search(
                            new SearchRequestModel({
                                searchText,
                                pageSize: this._searchResultSize,
                                filters: this.config().filters,
                                sortings: this.config().sortings
                            })
                        )
                        .pipe(map(response => response.value?.records ?? []))
                )
            )
            .subscribe(options => {
                this.options = options;
                this.isLoading = false;
            });
    }

    override writeValue(value?: TId): void {
        if (!value) {
            super.writeValue(undefined);
            this.select(undefined, true);
            this.options = [];
            return;
        }

        this.isLoading = true;
        this.config().store.getListModel(value).subscribe({
            next: result => {
                super.writeValue(result.value?.id);
                this.select(result.value, true);
            },
            complete: () => this.isLoading = false
        });
    }

    input(): void {
        if (this.internalControl.value.length > 0) {
            this.internalSearchSubject.next(this.internalControl.value);
            this.inputChanged.emit(this.internalControl.value);
        } else {
            this.inputChanged.emit('');
            this.options = [];
            this.select(undefined);
        }
    }

    onBlur(): void {
        if (this.isInvalidInput) {
            this.select(this.currentSelection);
        }
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value as TList;
        this.select(value);
        this.options = [value];
    }

    openDataSelectionDialog(event: MouseEvent): void {
        event.stopPropagation();

        if (this.isDisabled) {
            return;
        }

        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
            data: this.config().dataSelectionDialogConfig,
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe((result?: TList[]) => {
            if (this.isDisabled) {
                return;
            }

            this.isInvalidInput = false;

            if (!!result && result.length > 0) {
                this.select(result[0]);
            }

            this.markAsTouched();
        });
    }

    private select(option: OptionalType<TList>, isSetInternally = false): void {
        this.currentSelection = option;
        this.internalControl.setValue(option);
        this.isInvalidInput = false;

        if (!isSetInternally) {
            this.valueChanged(option?.id);

            if (!!option) {
                this.optionSelected.emit(option);
            }
        }
    }
}
