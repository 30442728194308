import { Component, computed, ElementRef, inject, signal, viewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';

import { BaseCustomColumnComponent } from '../../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { OrderArticleModel } from '../../../../../../models/order-article.model';
import { OptionalType } from '../../../../../../../core/models/types/optional.type';
import { OrdersStore } from '../../../../../../services/stores/orders.store';
import { OrderArticleSalesQuantitiesModel } from '../../../../../../models/responses/order-article-sales-quantities.model';

@Component({
    selector: 'arc-order-article-sales-history-column',
    templateUrl: './order-article-sales-history-column.component.html',
    styleUrl: './order-article-sales-history-column.component.scss',
    animations: [
        // animation for tooltip overlay
        trigger('tooltipAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    scale: 0.8
                }),
                animate(
                    '0.15s ease-in-out',
                    style({
                        opacity: 1,
                        scale: 1
                    })
                )
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                    scale: 1
                }),
                animate(
                    '0.15s ease-in-out',
                    style({
                        opacity: 0,
                        scale: 0.8
                    })
                )
            ])
        ])
    ]
})
export class OrderArticleSalesHistoryColumnComponent extends BaseCustomColumnComponent<OrderArticleModel> {
    readonly portal = viewChild.required(CdkPortal);
    readonly icon = viewChild.required('icon', { read: ElementRef });

    readonly performanceData = signal<OptionalType<OrderArticleSalesQuantitiesModel[]>>(undefined);
    readonly totalSoldQuantity = computed(() => this.performanceData()?.reduce((acc, curr) => acc + curr.quantitySoldTotal, 0) ?? 0);

    private overlayRef: OptionalType<OverlayRef>;
    private getPerformanceDataSub: OptionalType<Subscription>;

    private readonly ordersStore = inject(OrdersStore);
    private readonly overlay = inject(Overlay);

    show(): void {
        this.overlayRef = this.overlay.create({
            disposeOnNavigation: true,
            maxWidth: '98vw',
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.icon())
                // by default, position centered under icon. if not enough space, try centered above icon
                .withPositions([
                    { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 8 },
                    { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -8 }
                ])
        });

        this.overlayRef.attach(this.portal());
        if (!this.performanceData()) {
            this.getPerformanceDataSub = this.ordersStore
                .getArticleSalesQuantities(this.item.orderId, this.item.articleId)
                .subscribe(result => {
                    this.performanceData.set(result.value);
                });
        }
    }

    hide(): void {
        this.getPerformanceDataSub?.unsubscribe();
        this.getPerformanceDataSub = undefined;
        this.overlayRef?.detach();
        this.overlayRef = undefined;
    }
}
