<form [formGroup]="quickAddFormGroup" (submit)="quickAdd()" class="flex gap-4 items-center flex-wrap">
    <arc-quick-search #quickAddArticleControl label="Orders.Edit.Articles.AddArticle"
        [config]="quickSearchConfig!" (optionSelected)="quickAddArticleChanged($event)"
        [formControl]="quickAddFormGroup.controls.articleSupplierId" class="basis-[200px] max-w-[400px] grow shrink-0">
    </arc-quick-search>

    <arc-number-input #quickAddQuantityControl label="Orders.Edit.Articles.OrderQuantity"
        [unit]="quickAddQuantityUnit()" [isUnitTranslated]="true"
        [formControl]="quickAddFormGroup.controls.orderQuantity" class="basis-[100px] max-w-[150px] grow shrink-0">
    </arc-number-input>
    <button mat-stroked-button (click)="quickAdd()" [disabled]="isQuickAddLoading" class="!size-[48px] grid place-items-center">
        @if (isQuickAddLoading) {
            <mat-spinner [diameter]="24"></mat-spinner>
        } @else {
            <arc-icon icon="add" [size]="24" />
        }
    </button>

    @if (!!quickAddOrderArticle()) {
        <div class="min-w-fit p-2 grow shrink-0 flex gap-4 items-center bg-light-gray dark:bg-dark-gray rounded-sm">
            <div class="min-w-max grow shrink-0">
                <span class="text-on-app-light">
                    <span class="text-on-app">
                        {{ quickAddBuyingPrice() | arcCurrency }}
                    </span>
                    <span class="ml-2 text-sm text-on-app-light">
                        {{ (quickAddBuyingPriceExclusive()
                            ? 'Orders.Edit.Articles.BuyingPriceExclusive'
                            : 'Orders.Edit.Articles.BuyingPriceInclusive') | transloco }}
                    </span>
                </span>
            </div>        
            <div class="min-w-max grow shrink-0 flex flex-col">
                <span class="truncate text-xs">
                    {{ quickAddTotalQuantity() | arcNumber : 0 : undefined : ' ' + (quickAddOrderArticle()?.articleUnitTitle ?? '') }}
                </span>
                <span>{{ quickAddTotal() | arcCurrency }}</span>
            </div>
        </div>
    }
</form>