import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { BaseColumnModel } from '../models/column-types/base-column.model';
import { OptionalType } from '../../../core/models/types/optional.type';
import { Identifyable } from '../../../core/abstractions/identifyable';
import { BaseComponent } from '../../abstractions/base.component';

@Directive()
export abstract class BaseColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseComponent implements OnInit {
    @HostBinding('class') classes = 'w-full';

    @Input() columnModel!: BaseColumnModel;
    @Input() item!: T;
    @Input() formGroup?: FormGroup<{ [K in keyof T]: AbstractControl<any, any> }>;

    get control(): OptionalType<FormControl> {
        return this.columnModel.propertyName
            ? this.formGroup?.get(this.columnModel.propertyName) as FormControl
            : undefined;
    }

    ngOnInit(): void {
        if (this.columnModel.isDisabled && !!this.control) {
            this.control.disable();
        }

        // editable columns should always be full height
        if (this.columnModel.isEditable && !!this.control) {
            this.classes = 'w-full h-full';
        }
    }

    getItemValue(propertyName?: string): any {
        return this.columnModel.getItemValue(this.item, propertyName);
    }

    isBold(propertyName?: string): boolean {
        return !!this.columnModel.isBold
            && this.columnModel.isBold(this.item, this.columnModel.getValue(propertyName), propertyName);
    }
}
