import { Component, ElementRef, OnDestroy, OnInit, computed, effect, inject, input, signal, viewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { TicketModel } from '../../../../app/models/ticket.model';
import { BaseComponent } from '../../../abstractions/base.component';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { TicketStore } from '../../../../app/services/stores/ticket.store';
import { TicketMessageModel } from '../../../../app/models/ticket-message.model';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { BlobModel } from '../../../../app/models/blob.model';
import { Utils } from '../../../../core/utils/tools/utils.tools';
import { SidebarHeaderService } from '../../../../core/services/sidebar-header.service';
import { TranslationService } from '../../../../core/services/translation.service';
import { TicketEditHeaderAdditionalInfoComponent } from '../ticket-edit-header-additional-info/ticket-edit-header-additional-info.component';
import { TicketStatusEnum } from '../../../../app/models/enums/ticket-status.enum';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { MarkdownEditorComponent } from '../../../markdown-editor/markdown-editor.component';
import { TicketMessageOrEventModel, TicketMessageTypeEnum } from '../message-or-event';
import { TicketHistoryModel } from '../../../../app/models/ticket-history.model';
import { RateTicketDialogComponent } from '../../../dialogs/rate-ticket-dialog/rate-ticket-dialog.component';
import { AlertService } from '../../../../core/services/alert.service';
import { TicketService } from '../../../../core/services/ticket.service';

@Component({
    selector: 'arc-view-ticket',
    templateUrl: './view-ticket.component.html',
    styleUrl: './view-ticket.component.scss'
})
export class ViewTicketComponent extends BaseComponent implements OnInit, OnDestroy {
    readonly markdownEl = viewChild<MarkdownEditorComponent>('markdown');
    readonly scrollContainer = viewChild<ElementRef>('scrollContainer');

    readonly ticket = input.required<TicketModel>();
    readonly isAdding = signal(false);
    readonly isResolving = signal(false);
    readonly messagesOrEvents = computed(() => this.getMessagesOrEvents(this.ticketService.selectedTicket()));
    readonly isResolveSuggested = computed(() => this.ticket().status === TicketStatusEnum.ResolveSuggested);
    formGroup = new FormGroup({
        text: new ArcFormControl('', Validators.required),
        attachments: new ArcFormControl<OptionalType<BlobModel[]>>(undefined)
    });
    fileControl = new ArcFormControl<OptionalType<File>>(undefined);
    TicketMessageTypeEnum = TicketMessageTypeEnum;

    private readonly ticketService = inject(TicketService);
    private readonly ticketStore = inject(TicketStore);
    private readonly headerService = inject(SidebarHeaderService);
    private readonly sidebarService = inject(SidebarService);
    private readonly translationService = inject(TranslationService);
    private readonly alertService = inject(AlertService);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();

        effect(() => {
            if (!!this.scrollContainer()?.nativeElement) {
                setTimeout(() => {
                    this.scrollContainer()!.nativeElement.scrollTop = this.scrollContainer()?.nativeElement.scrollHeight;
                }, 200);
            }
        });
    }

    ngOnInit(): void {
        this.fileControl.valueChanges.subscribe(async file => {
            if (!file) {
                return;
            }

            const newBlob = {
                id: 0,
                blobDataId: Utils.newGuid(),
                blobData: Array.from(new Uint8Array(await file.arrayBuffer())),
                fileMimeType: file.type,
                fileName: file.name
            };

            this.formGroup.controls.attachments.patchValue([...(this.formGroup.value.attachments || []), newBlob]);
            this.fileControl.reset();
        });

        this.setupHeader();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.resetHeader();
    }

    asMessage(item: TicketMessageModel | TicketHistoryModel): TicketMessageModel {
        return item as TicketMessageModel;
    }

    asEvent(item: TicketMessageModel | TicketHistoryModel): TicketHistoryModel {
        return item as TicketHistoryModel;
    }

    addMessage(): void {
        if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            this.markdownEl()?.focus();

            return;
        }

        this.isAdding.set(true);

        const createTicketModel = {
            text: this.formGroup.value.text || '',
            attachments: this.formGroup.value.attachments || []
        };

        this.ticketStore
            .addMessage(this.ticket().id, createTicketModel)
            .pipe(switchMap(() => this.ticketStore.getTicket(this.ticket().id)))
            .subscribe({
                next: () => {
                    this.formGroup.controls.text.setValue('');
                    this.sidebarService.closeRight();
                },
                complete: () => {
                    this.isAdding.set(false);
                }
            });
    }

    resolveTicketConfirm(): void {
        this.alertService.showConfirm(
            'Tickets.AreYouSureToResolve',
            undefined,
            isConfirmed => {
                if (isConfirmed) {
                    this.resolveTicket();
                }
            },
            undefined,
            true,
            {}
        );
    }

    resolveTicket(): void {
        this.isResolving.set(true);

        const dialogRef = this.matDialog.open(RateTicketDialogComponent, {});
        dialogRef.afterClosed().subscribe(data => {
            const ticketResolveRequestModel = {
                text: this.formGroup.value.text || '',
                attachments: this.formGroup.value.attachments || [],
                ratingStars: data?.ratingStars,
                ratingReason: data?.ratingReason
            };

            this.ticketStore
                .resolveTicket(this.ticket().id, ticketResolveRequestModel)
                .pipe(switchMap(() => this.ticketStore.getTicket(this.ticket().id)))
                .subscribe({
                    next: () => {
                        this.formGroup.controls.text.setValue('');
                        this.sidebarService.closeRight();
                    },
                    complete: () => {
                        this.isResolving.set(false);
                    }
                });
        });
    }

    removeFile(blobDataId?: string): void {
        if (!blobDataId) {
            return;
        }

        const filteredAttachments = (this.formGroup.value.attachments || []).filter(x => x.blobDataId !== blobDataId);
        this.formGroup.controls.attachments.patchValue(filteredAttachments);
    }

    cancel(): void {
        this.headerService.reset();
        this.headerService.handleBackButtonClicked();
    }

    private setupHeader(): void {
        const headerText = this.translationService.getText('Tickets.View.Ticket') + ` #${this.ticket().id}`;

        this.headerService.title.set(headerText);
        this.headerService.hasCloseButton.set(false);
        this.headerService.hasBackButton.set(true);
        this.headerService.item.set(this.ticket());
        this.headerService.additionalInfoComponent.set(TicketEditHeaderAdditionalInfoComponent);
    }

    private resetHeader(): void {
        const headerText = this.translationService.getText('Tickets.ComponentTitle');

        this.headerService.title.set(headerText);
        this.headerService.hasCloseButton.set(true);
        this.headerService.hasBackButton.set(false);
        this.headerService.additionalInfoComponent.set(undefined);
    }

    private getMessagesOrEvents(ticket?: TicketModel): TicketMessageOrEventModel[] {
        if (!ticket) {
            return [];
        }

        let lastCreatedByEmail: string | undefined;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        let lastIsMyResponse: boolean | undefined;
        const messages =
            ticket.messages.map(m => {
                const showAvatar = lastCreatedByEmail !== m.createdByEmail || lastIsMyResponse !== m.isMyResponse;
                lastCreatedByEmail = m.createdByEmail;
                lastIsMyResponse = m.isMyResponse;
                m.showAvatar = showAvatar;
                return {
                    id: m.id.toString(),
                    date: m.createdDate,
                    item: m,
                    type: TicketMessageTypeEnum.Message
                };
            }) ?? [];
        const events =
            ticket.history.map(h => ({
                id: Utils.newGuid(),
                date: h.date,
                item: h,
                type: TicketMessageTypeEnum.Event
            })) ?? [];

        return [...messages, ...events].sort((a, b) => a.date.getTime() - b.date.getTime());
    }
}
