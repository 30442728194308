import { Component, HostBinding, OnInit, computed, inject } from '@angular/core';

import { CreateTicketService } from '../../../../core/services/create-ticket.service';
import { CreateTicketStepEnum } from '../../../../app/models/enums/create-ticket-step.enum';
import { SidebarService } from '../../../../core/services/sidebar.service';

@Component({
    selector: 'arc-new-ticket',
    templateUrl: './new-ticket.component.html',
    styleUrl: './new-ticket.component.scss'
})
export class NewTicketComponent implements OnInit {
    @HostBinding('class') classList = 'flex flex-col grow';

    CreateTicketStepEnum = CreateTicketStepEnum;

    currentStep = computed(() => this.createTicketService.currentStep());

    readonly createTicketService = inject(CreateTicketService);

    private readonly sidebarService = inject(SidebarService);

    ngOnInit(): void {
        this.createTicketService.startNewTicket();
    }

    cancel(): void {
        this.sidebarService.closeRight();
    }
}
