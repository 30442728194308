@if(isLoading()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
}
@else if(!!selectedTicket()) {
    <arc-view-ticket class="h-full flex flex-col" [ticket]="selectedTicket()!"></arc-view-ticket>
}
@else if(hasOpenTickets()) {
    <div class="px-6 h-full">
        <div class="flex flex-col mt-4">
            <mat-button-toggle-group [(value)]="ticketService.showOnlyActionRequired">
                <mat-button-toggle [value]="false">{{ 'Tickets.StatusAllOpen' | transloco }}
                </mat-button-toggle>
                <mat-button-toggle [value]="true">{{ 'Tickets.StatusRequiringAction' | transloco }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="flex flex-col gap-4 mt-4 pb-4">
            @for (ticket of ticketsList(); track ticket.id) {
                <arc-ticket-list-item [ticket]="ticket" (ticketSelected)="selectTicket($event)"></arc-ticket-list-item>
            }
        </div>
    </div>    
}