import { FormControl } from '@angular/forms';
import { Signal } from '@angular/core';
import { Observable } from 'rxjs';

import { TextAlignmentEnum } from '../../../../core/models/enums/text-alignment.enum';
import { ColumnTypeEnum } from '../enums/column-type.enum';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';

export abstract class BaseColumnModel {
    columnTitleKey!: string;
    columnTitleSuffix?: string;
    columnTitleAlignment = TextAlignmentEnum.Left;
    shouldHideColumnTitle? = false;
    propertyName?: string;
    sortColumn?: string;
    widthPixels!: number;
    maxWidthPixels?: number;
    allowSort = true;
    isCompact = false;
    isEditable = false;
    isDisabled = false;
    requiredPermission?: PermissionsEnum;
    requiredPermissionType?: PermissionTypeEnum;
    titleSignal?: Signal<string>;
    actionIcon?: string;
    canFilterEditableColumn = false;
    titleActionIcon?: string;
    titleAction?: () => void;
    action?: (control: FormControl) => Observable<any>;
    customFormatter?: (record: any, value: any, propertyName?: string) => string;
    isBold?: (record: any, value: any, propertyName?: string) => boolean;

    protected constructor(readonly columnType: ColumnTypeEnum, init?: Partial<BaseColumnModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }

    get identifier(): string {
        return this.propertyName ?? this.columnTitleKey;
    }

    getItemValue(item: any, propertyName?: string): any {
        return !!this.customFormatter
            ? this.customFormatter(item, this.getValue(item, propertyName), propertyName)
            : this.getValue(item, propertyName);
    }

    getValue(item: any, propertyName?: string): any {
        return !!propertyName && propertyName in item ? item[propertyName as keyof typeof item] : '';
    }
}
