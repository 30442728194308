import { Component, EventEmitter, OnInit, Output, inject, signal, computed } from '@angular/core';

import { TicketStore } from '../../../../app/services/stores/ticket.store';
import { CreateTicketService } from '../../../../core/services/create-ticket.service';
import { TranslationService } from '../../../../core/services/translation.service';
import { TicketActionButtonModel } from '../../../../core/models/ticket-action-button.model';
import { CreateTicketStepEnum } from '../../../../app/models/enums/create-ticket-step.enum';
import { HelpStore } from '../../../../app/services/stores/help.store';
import { SidebarHeaderService } from '../../../../core/services/sidebar-header.service';
import { BaseComponent } from '../../../abstractions/base.component';

@Component({
    selector: 'arc-create-ticket-response',
    templateUrl: './create-ticket-response.component.html',
    styleUrl: './create-ticket-response.component.scss'
})
export class CreateTicketResponseComponent extends BaseComponent implements OnInit {
    @Output() readonly solutionAccepted = new EventEmitter<void>();

    readonly isLoading = signal(false);

    createTicketResponse = computed(() => this.createTicketService.createTicketResponse());
    recommendedArticles = computed(() => this.createTicketService.createTicketResponse()?.recommendedHelpTopics || []);
    selectedArticle = computed(() => this.createTicketService.selectedArticle());

    private readonly ticketStore = inject(TicketStore);
    private readonly createTicketService = inject(CreateTicketService);
    private readonly translationService = inject(TranslationService);
    private readonly helpStore = inject(HelpStore);
    private readonly headerService = inject(SidebarHeaderService);

    ngOnInit(): void {
        const createTicket = new TicketActionButtonModel({
            id: 'create-ticket',
            text: this.translationService.getText('Tickets.Response.CreateTicket'),
            step: CreateTicketStepEnum.RequestCreated,
            color: undefined,
            type: 'stroked',
            clickFn: () => this.createTicket()
        });
        const problemSolved = new TicketActionButtonModel({
            id: 'problem-solved',
            text: this.translationService.getText('Tickets.Response.ProblemSolved'),
            step: CreateTicketStepEnum.RequestCreated,
            clickFn: () => this.problemSolved()
        });

        this.createTicketService.addButtons(createTicket, problemSolved);
        this.addSubscriptions(this.headerService.backButtonClicked.subscribe(() => this.handleArticleBack()));
    }

    problemSolved(): void {
        const createTicketResponse = this.createTicketService.createTicketResponse();

        if (!createTicketResponse) {
            return;
        }

        this.ticketStore.resolveRequest(createTicketResponse.requestId).subscribe(() => this.createTicketService.acceptSolution());
    }

    createTicket(): void {
        this.createTicketService.createTicket();
    }

    selectArticle(articleId: number): void {
        this.createTicketService.selectedArticle.set(undefined);
        this.isLoading.set(true);
        this.helpStore.getHelpArticle(articleId).subscribe(r => {
            this.createTicketService.selectedArticle.set(r.value);
            this.isLoading.set(false);
        });
    }

    handleArticleBack(): void {
        this.createTicketService.selectedArticle.set(undefined);
    }
}
