import { Component, Signal, WritableSignal, computed, effect, inject, signal, untracked } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, finalize, map, of, switchMap, tap } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArticleModel } from '../../../../../models/article.model';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { SizesStore } from '../../../../../services/stores/sizes.store';
import { ColorsStore } from '../../../../../services/stores/colors.store';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { ColorListModel } from '../../../../../models/color-list.model';
import { SizeListModel } from '../../../../../models/size-list.model';
import { KeyValueModel } from '../../../../../../core/models/key-value.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { MainArticlesStore } from '../../../../../services/stores/main-articles.store';
import { CurrencyColumnModel } from '../../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ButtonColumnModel } from '../../../../../../components/dynamic-table/models/column-types/button-column.model';
import { ArticleVariantEditModel } from '../../../../../models/article-variant-edit.model';
import { ArticleVariantTableEditModel } from '../../../../../models/article-variant-table-edit-model';
import { ArticleVariantEditModalComponent } from './components/article-variant-edit-modal/article-variant-edit-modal.component';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { ArticleSupplierModel } from '../../../../../models/article-supplier.model';
import { ArticleImageModel } from '../../../../../models/responses/article-image.model';
import { EntityTagModel } from '../../../../../models/entity-tag.model';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArticleEditBaseDataComponent } from '../article-edit-base-data/article-edit-base-data.component';
import { ArticleEditSuppliersComponent } from '../article-edit-suppliers/article-edit-suppliers.component';
import { ArticleVariantModel } from '../../../../../models/article-variant.model';
import { ArticleEditPropertiesComponent } from '../article-edit-properties/article-edit-properties.component';
import { ColoredStackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { ArticleSupplierEditModel } from '../../../../../models/requests/article-supplier-edit.model';

@Component({
    selector: 'arc-article-edit-variants',
    templateUrl: './article-edit-variants.component.html',
    styleUrl: './article-edit-variants.component.scss'
})
export class ArticleEditVariantsComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    override formGroup = new FormGroup({
        variants: new ArcFormControl<ArticleVariantEditModel[]>([])
    });
    selectedSizes: SizeListModel[] = [];
    selectedColors: ColorListModel[] = [];
    variantsTableConfig: EditableTableConfigModel<ArticleVariantTableEditModel>;
    isLoading = signal(false);
    sizeControl = new ArcFormControl(undefined, [Validators.required, Validators.minLength(1)]);
    colorControl = new ArcFormControl(undefined, [Validators.required, Validators.minLength(1)]);

    private originalVariantsOrder: ArticleVariantModel[] = [];
    private articlesIdentifications: string[] = [];
    private hasInitialLoadFinished = false;
    private idsProcessingEan: number[] = [];
    private isLoadingIdentificationsBatch = false;
    private readonly onNewIdentificationsLoaded = new Subject<void>();
    private readonly wasPriceChangedByUser: WritableSignal<boolean>;
    private readonly wasOrderNumberChangedByUser: WritableSignal<boolean>;
    private readonly wasMainSupplierChangedByUser: WritableSignal<boolean>;
    private readonly wasBuyingPriceChangedByUser: WritableSignal<boolean>;
    private readonly wasTagsChangedByUser: WritableSignal<boolean>;
    private readonly price: WritableSignal<OptionalType<number>>;
    private readonly orderNumber: WritableSignal<OptionalType<string>>;
    private readonly buyingPrice: Signal<OptionalType<number>>;
    private readonly colorsStore = inject(ColorsStore);
    private readonly sizesStore = inject(SizesStore);
    private readonly mainArticlesStore = inject(MainArticlesStore);
    private readonly matDialog = inject(MatDialog);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly tags: WritableSignal<EntityTagModel[]>;
    private readonly seasonId: WritableSignal<OptionalType<number>>;
    private readonly mainSupplier: WritableSignal<OptionalType<ArticleSupplierEditModel>>;
    private readonly currentOrderNumber = computed(() => this.orderNumber() || this.mainSupplier()?.orderNumber);
    private readonly identificationsPageSize = 50;

    constructor() {
        super();
        // This is required for now, because signals don't let us know when the first change was sent, unlike ngOnChanges
        // We should look for a more elegant solution, if that's never provided.
        // One idea would be to have item as a signal and read from it directly and have the original item (as we have today)
        // to compare if the value was changed, that way we wouldn't need all these extra signals.
        // Ref: https://github.com/angular/angular/issues/54372
        this.wasPriceChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditBaseDataComponent.wasPriceChangedByUserSignal,
            false
        );
        this.wasOrderNumberChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditSuppliersComponent.wasOrderNumberChangedByUserSignal,
            false
        );
        this.wasMainSupplierChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditSuppliersComponent.wasMainSupplierChangedByUserSignal,
            false
        );
        this.wasBuyingPriceChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditBaseDataComponent.wasBuyingPriceChangedByUserSignal,
            false
        );
        this.wasTagsChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditPropertiesComponent.wasTagsChangedByUserSignal,
            false
        );
        this.price = this.sharedDataService.getOrCreateSignal(ArticleEditBaseDataComponent.priceSignal);
        this.orderNumber = this.sharedDataService.getOrCreateSignal(ArticleEditSuppliersComponent.orderNumberSignal);
        this.buyingPrice = this.sharedDataService.getOrCreateSignal(ArticleEditBaseDataComponent.buyingPriceSignal);
        this.tags = this.sharedDataService.getOrCreateSignalWithValue(ArticleEditPropertiesComponent.tagsSignal, []);
        this.seasonId = this.sharedDataService.getOrCreateSignal(ArticleEditPropertiesComponent.seasonIdSignal);
        this.mainSupplier = this.sharedDataService.getOrCreateSignal(ArticleEditSuppliersComponent.mainSupplierSignal);
        this.variantsTableConfig = new EditableTableConfigModel<ArticleVariantTableEditModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    ean: new ArcFormControl('', [Validators.required, Validators.maxLength(20), this.duplicateEanValidator()]),
                    price: new ArcFormControl<number>(this.price(), CustomValidators.number()),
                    isRemoved: new ArcFormControl<boolean>(false),
                    color: new ArcFormControl(undefined),
                    colorId: new ArcFormControl<number>(undefined),
                    colorValue: new ArcFormControl(undefined),
                    size: new ArcFormControl(undefined),
                    sizeId: new ArcFormControl<number>(undefined),
                    mainArticleId: new ArcFormControl<number>(this.item().id),
                    orderNumber: new ArcFormControl<OptionalType<string>>(this.currentOrderNumber()),
                    buyingPrice: new ArcFormControl(this.buyingPrice() || 0, Validators.required),
                    tags: new ArcFormControl<EntityTagModel[]>(this.tags()),
                    articleImages: new ArcFormControl<ArticleImageModel[]>([])
                }),
            onRowInit: fg => {
                this.updateEan(fg);

                if (fg.value['isRemoved']) {
                    fg.disable();
                } else {
                    fg.enable();
                }
            },
            rowHeightPx: 63,
            shouldHideAddButton: true,
            allowDelete: false,
            showPaging: true,
            columns: [
                new ColoredStackedColumnModel({
                    columnTitleKey: 'Articles.Edit.Variants.Variant',
                    propertyName: 'size',
                    propertyName2: 'color',
                    color: 'colorValue',
                    isColorInHex: true,
                    isEditable: true,
                    widthPixels: 200
                }),
                new StringColumnModel({
                    propertyName: 'ean',
                    isEditable: true,
                    widthPixels: 190,
                    columnTitleKey: 'Articles.Edit.Variants.EAN',
                    action: this.generateNewIdentificationCallback.bind(this),
                    actionIcon: 'rotate_left'
                }),
                new CurrencyColumnModel({
                    propertyName: 'price',
                    columnTitleKey: 'Articles.Edit.Variants.Price',
                    widthPixels: 160,
                    isEditable: true
                }),
                new ButtonColumnModel({
                    propertyName: 'editButton',
                    icon: () => 'edit',
                    onClickFunction: (item: ArticleVariantTableEditModel, formGroup) =>
                        this.matDialog
                            .open(ArticleVariantEditModalComponent, {
                                data: {
                                    recordData: item,
                                    mainArticleTags: this.tags(),
                                    hasMainSupplier:
                                        !!this.item().articleSuppliers.find(s => s.isMainSupplier) || this.mainSupplier()?.isMainSupplier
                                },
                                width: '550px',
                                maxHeight: '98svh',
                                maxWidth: '98vw',
                                disableClose: true
                            })
                            .afterClosed()
                            .subscribe(resp => {
                                if (!!resp) {
                                    item = resp;

                                    formGroup?.controls.orderNumber?.setValue(resp.orderNumber);
                                    formGroup?.controls.buyingPrice?.setValue(resp.buyingPrice);
                                    formGroup?.controls.articleImages?.setValue(resp.articleImages);
                                    formGroup?.controls.tags?.setValue(
                                        resp.tags.map((t: KeyValueModel) => ({ id: Number(t.key), title: t.value ?? '' }))
                                    );
                                }
                            }),
                    isButtonDisabled: (item: ArticleVariantTableEditModel) => item.isRemoved,
                    columnTitleKey: '',
                    widthPixels: 40
                }),
                new ButtonColumnModel({
                    propertyName: 'addRemoveButton',
                    icon: (item: ArticleVariantTableEditModel) => (item.isRemoved ? 'add' : 'close'),
                    onClickFunction: (item: ArticleVariantTableEditModel, formGroup?: FormGroup) => {
                        item.isRemoved = !item.isRemoved;

                        if (!!formGroup) {
                            formGroup.controls['isRemoved'].setValue(item.isRemoved);

                            if (item.isRemoved) {
                                formGroup.disable();
                            } else {
                                this.updateEan(formGroup);
                                formGroup.enable();
                            }
                        }
                    },
                    columnTitleKey: '',
                    widthPixels: 40
                })
            ]
        });

        effect(() => {
            this.wasPriceChangedByUser();
            this.price();
            this.wasOrderNumberChangedByUser();
            this.currentOrderNumber();
            this.wasBuyingPriceChangedByUser();
            this.buyingPrice();
            this.wasTagsChangedByUser();
            this.tags();
            this.seasonId();
            this.updateValues();
        });
    }

    override onItemSet(): void {
        this.originalVariantsOrder = this.isCreate() ? [] : Utils.deepCopy(this.item().variants);

        this.item().variants.forEach(v => {
            if (!this.selectedSizes.find(s => s.id === v.sizeId)) {
                this.selectedSizes.push({ id: v.sizeId, description: v.size });
            }

            if (!this.selectedColors.find(c => c.id === v.colorId)) {
                this.selectedColors.push({
                    id: v.colorId,
                    description: v.color,
                    colorValue: v.colorValue,
                    foreColorValue: v.foreColorValue
                });
            }
        });
        this.formGroup.patchValue({ variants: this.getVariantsToLoad() });
        this.updateTable(true);
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return { variants: this.getVariantsToSave() };
    }

    override onBrokenRulesLoad(): string[] {
        return this.variantsTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override updateAndCheckValidity(): boolean {
        this.colorControl.markAsTouched();
        this.colorControl.updateValueAndValidity();
        this.sizeControl.markAsTouched();
        this.sizeControl.updateValueAndValidity();

        return super.updateAndCheckValidity() && this.colorControl.valid && this.sizeControl.valid;
    }

    getColorId(color: ColorListModel): number {
        return color.id;
    }

    colorDisplayFn(option: ColorListModel): string {
        return option.description;
    }

    colorBgColorDisplay(option: ColorListModel): OptionalType<string> {
        return option.colorValue ? `#${option.colorValue}` : undefined;
    }

    colorFgColorDisplay(option: ColorListModel): OptionalType<string> {
        return option.foreColorValue ? `#${option.foreColorValue}` : undefined;
    }

    colorsSearch(query: string): Observable<ColorListModel[]> {
        return this.colorsStore.search(new SearchRequestModel({ searchText: query })).pipe(map(response => response.value?.records || []));
    }

    getSizeId(size: SizeListModel): number {
        return size.id;
    }

    sizeDisplayFn(option: SizeListModel): string {
        return option.description;
    }

    sizesSearch(query: string): Observable<SizeListModel[]> {
        return this.sizesStore.search(new SearchRequestModel({ searchText: query })).pipe(map(response => response.value?.records || []));
    }

    addSize(name: string): Observable<SizeListModel> {
        return this.sizesStore.add({ description: name, id: 0 }).pipe(map(res => ({ id: res.value!, description: name })));
    }

    addColor(name: string): Observable<ColorListModel> {
        return this.colorsStore.add({ description: name, id: 0 }).pipe(
            switchMap(res => this.colorsStore.get(res.value!)),
            map(res => res.value!)
        );
    }

    updateTable(isInitialLoad = false): void {
        if (!isInitialLoad && !this.hasInitialLoadFinished) {
            return;
        }

        if (this.selectedColors.length < 1 || this.selectedSizes.length < 1) {
            this.formGroup.patchValue({ variants: [] });
            this.hasInitialLoadFinished = true;

            return;
        }

        this.isLoading.set(true);

        // adding timeout to prevent user to be blocked as this code takes a long time.
        setTimeout(() => {
            const variants = this.formGroup.controls.variants;
            const articleVariants: ArticleVariantTableEditModel[] = [];

            this.selectedSizes.forEach(s =>
                this.selectedColors.forEach(c => {
                    const currentVariant = variants.value.find(v => v.colorId === c.id && v.sizeId === s.id);

                    if (!currentVariant) {
                        articleVariants.push({
                            id: 0,
                            sizeId: s.id,
                            size: s.description,
                            colorId: c.id,
                            color: c.description,
                            colorValue: c.colorValue,
                            price: this.price() || 0,
                            buyingPrice: this.buyingPrice() || 0,
                            orderNumber: this.currentOrderNumber(),
                            isRemoved: isInitialLoad,
                            mainArticleId: this.item().id,
                            tags: this.tags(),
                            seasonId: this.seasonId(),
                            articleImages: [],
                            identifications: [],
                            articleSuppliers: []
                        });
                    } else {
                        articleVariants.push({
                            ...currentVariant,
                            isRemoved: !!(currentVariant as any).isRemoved,
                            size: s.description,
                            color: c.description,
                            colorValue: c.colorValue
                        });
                    }
                })
            );
            this.formGroup.patchValue({ variants: articleVariants.sort((a, b) => (a.colorId < b.colorId ? -1 : 1)) });
            this.isLoading.set(false);

            this.hasInitialLoadFinished = true;
        });
    }

    private updateValues(): void {
        const currentFormVariants = this.formGroup.controls.variants.value;

        currentFormVariants.forEach(v => {
            if (!(v as any).isRemoved) {
                v.price = this.wasPriceChangedByUser() ? this.price() || 0 : v.price;
                v.orderNumber = this.wasOrderNumberChangedByUser() || this.wasMainSupplierChangedByUser()
                    ? this.currentOrderNumber()
                    : v.orderNumber;
                v.buyingPrice = this.wasBuyingPriceChangedByUser() ? this.buyingPrice() : v.buyingPrice;
                v.seasonId = this.seasonId();
                v.tags = this.wasTagsChangedByUser() ? this.tags() : v.tags;
            }
        });
        untracked(() => this.formGroup.patchValue({ variants: currentFormVariants }));
    }

    private updateEan(formGroup: FormGroup): void {
        const currentId = formGroup.value['id'];

        if (!formGroup.value['ean'] && !formGroup.value['isRemoved'] && !this.idsProcessingEan.includes(currentId)) {
            this.idsProcessingEan.push(currentId);

            if (this.articlesIdentifications.length > 0) {
                this.updateEanAndPatchValue(currentId, formGroup);
            } else {
                if (this.isLoadingIdentificationsBatch) {
                    const newIdsSub = this.onNewIdentificationsLoaded.subscribe(() => {
                        this.updateEanAndPatchValue(currentId, formGroup);
                        newIdsSub.unsubscribe();
                    });
                } else {
                    this.isLoadingIdentificationsBatch = true;
                    this.mainArticlesStore.generateNewArticlesIdentifications(this.identificationsPageSize)
                        .pipe(finalize(() => this.isLoadingIdentificationsBatch = false))
                        .subscribe(r => {
                            this.loadIdentificationsBatch(r.value || []);
                            this.updateEanAndPatchValue(currentId, formGroup);
                        });
                }
            }
        }
    }

    private updateEanAndPatchValue(currentId: any, formGroup: FormGroup): void {
        const colorId = formGroup.value['colorId'];
        const sizeId = formGroup.value['sizeId'];
        const variants = this.formGroup.value.variants!;
        const newEan = this.articlesIdentifications.shift();
        const currentVariant = currentId > 0
            ? variants.find(v => v.id === currentId)!
            : variants.find(v => v.colorId === colorId && v.sizeId === sizeId)!;

        // settimeout should not be required, but it is. For some reason, either a bug in angular or the way we implemented
        // the editable table, the formgroup is NOT properly updated when we remove settimeout. There's always a hidden issue
        // that shows up in production. Unless we're 100% sure this has been fixed, this implementation should be kept.
        if (!!currentVariant) {
            setTimeout(() => {
                currentVariant.ean = newEan;
                this.formGroup.patchValue({ variants: variants });
            }, 0);
        } else {
            setTimeout(() => formGroup.patchValue({ ean: newEan }), 0);
        }

        const idx = this.idsProcessingEan.findIndex(id => id === currentId);

        if (idx > -1) {
            this.idsProcessingEan.splice(idx, 1);
        }
    }

    private generateNewIdentificationCallback(control: FormControl): Observable<any> {
        if (this.articlesIdentifications.length > 0) {
            control.setValue(this.articlesIdentifications.shift());
            return of();
        } else {
            if (this.isLoadingIdentificationsBatch) {
                return this.onNewIdentificationsLoaded.pipe(
                    tap(() => control.setValue(this.articlesIdentifications.shift()))
                );
            } else {
                this.isLoadingIdentificationsBatch = true;
                return this.mainArticlesStore.generateNewArticlesIdentifications(this.identificationsPageSize).pipe(
                    tap(result => {
                        this.loadIdentificationsBatch(result.value || []);
                        control.setValue(this.articlesIdentifications.shift());
                    }),
                    finalize(() => this.isLoadingIdentificationsBatch = false)
                );
            }
        }
    }

    private getVariantsTags(variant: ArticleVariantModel): EntityTagModel[] {
        const tagsToSkip = this.tags().map(t => `${t.id}${t.title}`);
        return variant.tags.filter(t => !tagsToSkip.includes(`${t.id}${t.title}`));
    }

    private getVariantsToLoad(): ArticleVariantEditModel[] {
        return this.item().variants.map(v => ({
            ...v,
            orderNumber: v.articleSuppliers.length > 0 ? v.articleSuppliers[0].orderNumber : undefined,
            ean: v.identifications.length > 0 ? v.identifications[0].identification : '',
            tags: this.getVariantsTags(v)
        }));
    }

    private getVariantsToSave(): ArticleVariantEditModel[] {
        const supplier: OptionalType<ArticleSupplierModel> = Utils.deepCopy(this.item().articleSuppliers[0]);

        if (!!supplier) {
            supplier.id = 0;
        }

        let articleVariants: ArticleVariantEditModel[] = [];
        const currentFormVariants = this.formGroup?.controls.variants.value.filter((v: any) => !v.isRemoved);

        // This is required to avoid triggering a change, when none was made.
        if (
            currentFormVariants.length === this.originalVariantsOrder.length &&
            currentFormVariants.map(ai => (ai as any).id).every(id => this.originalVariantsOrder.map(v => v.id).includes(id))
        ) {
            for (const id of this.originalVariantsOrder.map(v => v.id)) {
                articleVariants.push(currentFormVariants.find(ai => (ai as any).id === id)!);
            }
        } else {
            articleVariants = currentFormVariants;
        }

        return articleVariants.map(v => {
            const existingVariant = this.originalVariantsOrder.find(lv => lv.id === v.id);
            const articleSuppliers = existingVariant?.articleSuppliers || [];
            const identifications = existingVariant?.identifications || [];

            if (articleSuppliers.length > 0) {
                articleSuppliers[0].orderNumber = v.orderNumber || '';
            } else if (!!supplier) {
                articleSuppliers.push({
                    id: 0,
                    orderNumber: v.orderNumber || supplier.orderNumber || '',
                    personId: supplier.personId,
                    isMainSupplier: supplier.isMainSupplier,
                    buyingPrice: supplier.buyingPrice,
                    buyingPriceExclusive: supplier.buyingPriceExclusive,
                    minOrderQuantity: supplier.minOrderQuantity,
                    unitQuantity: supplier.unitQuantity
                });
            }

            if (identifications.length > 0) {
                identifications[0].identification = v.ean || '';
            } else {
                identifications.push({
                    id: 0,
                    identification: v.ean || ''
                });
            }

            let variantsTags: EntityTagModel[] = [];
            const currentTags = [...new Set(v.tags.concat(this.tags()))];

            // This is required to avoid triggering a change, when none was made.
            if (
                currentTags.length === existingVariant?.tags.length &&
                currentTags.map(t => t.id).every(id => existingVariant?.tags.map(tv => tv.id).includes(id))
            ) {
                for (const id of existingVariant?.tags.map(tv => tv.id)) {
                    variantsTags.push(currentTags.find(ct => ct.id === id)!);
                }
            } else {
                variantsTags = currentTags;
            }

            return {
                ...v,
                tags: variantsTags,
                articleSuppliers,
                identifications
            };
        });
    }

    private loadIdentificationsBatch(identifications: string[]): void {
        this.articlesIdentifications = identifications;
        this.onNewIdentificationsLoaded.next();
    }

    private duplicateEanValidator(): ValidatorFn {
        // eslint-disable-next-line no-null/no-null
        return (control: AbstractControl): { [key: string]: any } | null => {
            const currentEan = control.value;

            if (this.formGroup.value.variants?.some(v =>
                // eslint-disable-next-line eqeqeq
                (v.sizeId != control.parent?.value.sizeId || v.colorId != control.parent?.value.colorId)
                && v.ean === currentEan
                && !(v as any).isRemoved)
            ) {
                return { customError: { key: 'Articles.Edit.Variants.DuplicateEan' } };
            }

            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }
}
